import { atom } from "recoil";
import { arbTokenCA, ethTokenCA, usdcTokenCA, arb_usdc500PoolCA, arb_usdc3000PoolCA, arb_usdc10000PoolCA, eth_arb500PoolCA, eth_arb3000PoolCA, eth_arb10000PoolCA, arb_usdc3000InvestmentInfo, arbiscanDefualt, arb_usdc500InvestmentInfo } from "../common/web3/address";

export const menuDisabledAtom = atom({
  key: "menuDisabled",
  default: false
})

export const mobModalOpenAtom = atom({
  key: "mobModal",
  default: false 
});

export const mobModalContentsAtom = atom({
  key: "mobModalContents",
  default: ""
});

export const descPopUpAtom = atom({
  key: "descPopUp",
  default: {
    open: false,
    description: ""
  }
});

// Pool ARB-USDC Constructor
export const arbUsdcDataAtom = atom({
  key: "arbUsdcData",
  default: {
    poolFees: ["500", "3000", "10000"],
    fee500: {
      title: "BrincX LP",
      investment: 133181,
      t0: {
        year: "2023",
        month: "Dec",
        day: "05",
        hour: "06",
        minute: "15",
        second: "27"
      },
      index: 1,
      pool: arb_usdc500PoolCA,
      poolType: "arb-usdc",
      poolName: "ARB-USDC",
      fees: "0.05",
      arbiscanInvestmentUrl: arb_usdc500InvestmentInfo,
      arbiscanPoolUrl: arbiscanDefualt + arb_usdc500PoolCA,
      tokenPair: {
        token0: {
          symbol: "ARB",
          decimal: 18,
          address: arbTokenCA
        },
        token1: {
          symbol: "USDC",
          decimal: 6,
          address: usdcTokenCA
        },
      }
    },
    fee3000: {
      title: "BrincX LP",
      investment: 0,
      t0: {
        year: 2023,
        month: 10,
        day: 29
      },
      index: 1,
      pool: arb_usdc3000PoolCA,
      poolType: "arb-usdc",
      poolName: "ARB-USDC",
      fees: "0.3",
      arbiscanInvestmentUrl: arb_usdc3000InvestmentInfo,
      arbiscanPoolUrl: arbiscanDefualt + arb_usdc3000PoolCA,
      tokenPair: {
        token0: {
          symbol: "ARB",
          decimal: 18,
          address: arbTokenCA
        },
        token1: {
          symbol: "USDC",
          decimal: 6,
          address: usdcTokenCA
        },
      }
    },
    fee10000: {
      title: "BrincX LP",
      investment: 0,
      t0: {
        year: 2023,
        month: 10,
        day: 29
      },
      index: 1,
      pool: arb_usdc10000PoolCA,
      poolType: "arb-usdc",
      poolName: "ARB-USDC",
      fees: "1",
      arbiscanInvestmentUrl: "#",
      arbiscanPoolUrl: arbiscanDefualt + arb_usdc10000PoolCA,
      tokenPair: {
        token0: {
          symbol: "ARB",
          decimal: 18,
          address: arbTokenCA
        },
        token1: {
          symbol: "USDC",
          decimal: 6,
          address: usdcTokenCA
        },
      }

    }
  }
});

// Pool ETH-ARB Constructor
export const ethArbDataAtom = atom({
  key: "ethArbData",
  default: {
    poolFees: ["500", "3000", "10000"],
    fee500: {
      title: "BrincX LP",
      investment: 0,
      t0: {
        year: 2023,
        month: 10,
        day: 29
      },
      index: 1,
      pool: eth_arb500PoolCA,
      poolType: "eth-arb",
      poolName: "ETH-ARB",
      fees: "0.05",
      arbiscanInvestmentUrl: "#",
      arbiscanPoolUrl: arbiscanDefualt + eth_arb500PoolCA,
      tokenPair: {
        token0: {
          symbol: "ETH",
          decimal: 18,
          address: ethTokenCA
        },
        token1: {
          symbol: "ARB",
          decimal: 18,
          address: arbTokenCA
        },
      }
    },
    fee3000: {
      title: "BrincX LP",
      investment: 0,
      t0: {
        year: 2023,
        month: 10,
        day: 29
      },
      index: 1,
      pool: eth_arb3000PoolCA,
      poolType: "eth-arb",
      poolName: "ETH-ARB",
      fees: "0.3",
      arbiscanInvestmentUrl: "#",
      arbiscanPoolUrl: arbiscanDefualt + eth_arb3000PoolCA,
      tokenPair: {
        token0: {
          symbol: "ETH",
          decimal: 18,
          address: ethTokenCA
        },
        token1: {
          symbol: "ARB",
          decimal: 18,
          address: arbTokenCA
        },
      }
    },
    fee10000: {
      title: "BrincX LP",
      investment: 0,
      t0: {
        year: 2023,
        month: 10,
        day: 29
      },
      index: 1,
      pool: eth_arb10000PoolCA,
      poolType: "eth-arb",
      poolName: "ETH-ARB",
      fees: "1",
      arbiscanInvestmentUrl: "#",
      arbiscanPoolUrl: arbiscanDefualt + eth_arb10000PoolCA,
      tokenPair: {
        token0: {
          symbol: "ETH",
          decimal: 18,
          address: ethTokenCA
        },
        token1: {
          symbol: "ARB",
          decimal: 18,
          address: arbTokenCA
        },
      }
    },
  }
});
