import React, { useState, useEffect } from 'react'
import styled  from "styled-components";

const Button =styled.button`
    position: fixed;       
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    // background-color: #323b34;
    background-color: rgba(0,202,255,0.25);
    font-size: 13px;
    // color: #86bbcb;
    color: #00CAFF;
    opacity: 0; 
    z-index: -10; 
    transition: opacity 0.3s ease-in;
    cursor: pointer;
    &.active{z-index: 10; opacity: 1;}
    &:hover,&:focus,&:active{ outline: 0 none; }
    img{
        width: 18px;
        filter: invert(52%) sepia(97%) saturate(1144%) hue-rotate(155deg) brightness(102%) contrast(109%);
        // filter: invert(58%) sepia(83%) saturate(363%) hue-rotate(79deg) brightness(92%) contrast(93%);
        transform: rotate(180deg);
    }
`

function Topbutton(){

    const [ScrollY, setScrollY] = useState(0);
    const [BtnStatus, setBtnStatus] = useState(false);

    const handleFollow = () => {
        setScrollY(window.pageYOffset);
        if(ScrollY > 100) {
            setBtnStatus(true);
        } else {
            setBtnStatus(false);
        }
    }

    const handleTop = () => {  
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setScrollY(0); 
        setBtnStatus(false);
    }

    useEffect(() => {
        const watch = () => {
            window.addEventListener('scroll', handleFollow)
        }
        watch();
        return () => {
            window.removeEventListener('scroll', handleFollow)
        }
    })
    return <>
        <Button 
            className={BtnStatus ? "topBtn active" : "topBtn"} 
            onClick={handleTop} 
        ><img src={require("../../media/icons/ico-arrow.svg").default} alt="arrow"/></Button>
    </>
}

export default Topbutton