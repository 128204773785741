import dayjs from "dayjs";
import { Bar, BarChart, ResponsiveContainer, XAxis, Tooltip } from "recharts";
import { formatDollarAmount } from "../../common/web3/utils";

function VolFeeChart ({filteredChartData}) {

  const CustomToolTip = ({ payload, label}) => {

    return(
      <div className='pooldeviation-section__contents--chartWrap--tooltipWrapper'>
        <h1>Tick stats</h1>
        <div className='pooldeviation-section__contents--chartWrap--tooltipWrapper__dataLine'>
          <label>{dayjs(label).format('MMM D, YYYY')}</label>
          <label>{formatDollarAmount(payload[0]?.value)}</label>
        </div>
      </div>
    )
  }


  return (
      <ResponsiveContainer width="99%" height="99%">
        <BarChart
          data={filteredChartData}
          margin={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }}
        >
          <XAxis
            dataKey="time"
            axisLine={false}
            tickLine={false}
            tickFormatter={(time) => dayjs(time).format('DD')}
            minTickGap={10}
            />
          <Tooltip
            cursor={{ fill: "#666" }}
            content={<CustomToolTip />}
          />
          <Bar
            dataKey="value"
            fill={'#000'}
            shape={(props) => {
              return (
                <g>
                  <rect x={props.x} y={props.y} fill={"#56B2A4"} width={props.width} height={props.height} rx="2" />
                </g>
              )
            }}
          />
        </BarChart>
      </ResponsiveContainer>
  )
}

export default VolFeeChart;
