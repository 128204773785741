import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from './App';
import { RecoilRoot } from 'recoil';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";

const theme = createTheme({
  palette: {
    skyblue: {
      main: '#5BC7FA'
    }
  }
});

function getLibrary(provider) {
  return new Web3Provider(provider);
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </RecoilRoot>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

