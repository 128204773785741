import { useWeb3React } from "@web3-react/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { brincxGitbook, brincxGithub } from "../web3/address";
import { handleConnectWallet, shortAddress } from "../web3/utils";
import "./header.scss";

function Nav({buttonClick}) {
  const { account, active, activate } = useWeb3React();

  const itemLink = "mob__menu--link";

  return (
    <>
      <ul>
      <NavLink
          to="/MacroData"
          activeClassName="selected"
          className={itemLink}
          onClick={() => buttonClick()}
          >
          <li className="mob__menu--item">
            <button
              className={itemLink}
              >
              Macro
              {" "}<small className="mob__menu--itme--small"></small>
            </button>
          </li>
        </NavLink>
        <NavLink
          to="/Analytics"
          activeClassName="selected"
          className={itemLink}
          onClick={() => buttonClick()}
          >
          <li className="mob__menu--item">
            <button
              className={itemLink}
              >
              Analytics 
              {" "}<small className="mob__menu--itme--small"></small>
            </button>
          </li>
        </NavLink>
        <NavLink
          to="/Strategies"
          activeClassName="selected"
          className={itemLink}
          onClick={() => buttonClick()}
          >
          <li className="mob__menu--item">
            <button
              className={itemLink}
              >
              Strategies 
              {" "}<small className="mob__menu--itme--small"></small>
            </button>
          </li>
        </NavLink>
        {/* <li className="mob__menu--item">
          <button
            className={itemLink}
            onClick={() => window.open("https://test-brincx.netlify.app")}
            >
            Vault Test
            {" "}<small className="mob__menu--itme--small"></small>
          </button>
        </li> */}
        <li className="mob__menu--item">
          <button
            className={itemLink}
            onClick={() =>
              window.open(
                brincxGitbook,
                "_blank"
              )
            }
          >
            Gitbook
          </button>
        </li>
        <li className="mob__menu--item">
          <button
            className={itemLink}
            onClick={() =>
              window.open(brincxGithub, "_blank")
            }
          >
            Github
          </button>
        </li>
      </ul>
      <button className={`menu__btn__box--walletBtn-onMob`} onClick={() => handleConnectWallet(active, activate)}>
        {!active ?
          "Connect Wallet"
          :
          <span style={{fontWeight: "bold", color: "#959595"}}>{shortAddress(account)}</span>
            
        }
      </button>
    </>
  );
}

export default Nav;
