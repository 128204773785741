import './analytics.scss'
import React from 'react'
import AnalyticsSummary from '../../components/analyticsStats/AnalyticsSummary';
import { arbUsdcDataAtom } from '../../store/Atom';
import { useRecoilValue } from 'recoil';


function Analytics () {
  const poolArbUsdc = useRecoilValue(arbUsdcDataAtom);

  return( 
    <main id='main'>
      <section className="analytics__area">
        <h1 className='analytics__area--pageTitle'>Uniswap Pool data</h1>
        <AnalyticsSummary poolInitData={poolArbUsdc} initFee={"500"} />
      </section>
    </main>
  )
}

export default Analytics
