/* eslint-disable no-unused-vars */
import "./macroData.scss"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useEffect } from "react";
import { useState } from "react";
import { getAllDefiLlamaPoolsData, getApy, getBalanceOfTargetWallet, getEthApy, getTvl } from "../../common/web3/api";
import { calcUseWeightAverage, genRowData, truncateToDecimals, formatDollarAmount } from "../../common/web3/utils";
import { AgGridReact } from "ag-grid-react";
import { arbUsdcDataAtom } from "../../store/Atom";
import { useRecoilValue } from "recoil";
import { treasury } from "../../common/web3/address";

function MacroData() {
  const positionArbUsdc = useRecoilValue(arbUsdcDataAtom);
  const [topYieldAllChains, setTopYieldAllChains] = useState([]);
  const [topYieldArbitrum, setTopYieldArbitrum] = useState([]);
  const [topYieldSolana, setTopYieldSolana] = useState([]);
  const [topYieldUniswap, setTopYieldUniswap] = useState([]);
  const [economicIndicators, setEconomicIndicators] = useState({
    ethApy: 0,
    stableApy: 0,
    glpApy: 0,
    uniV3Apy: 0,
    brincXApy: 0,
  });

  // table settings
  const [rowData, setRowData] = useState([]);

  const defaultColDef = {
    editable: false,
    lockPosition: true
  }

  const projectCellRenderer = (params) => {
    return (
        <div className="macroData__imgCell">
          {/* <img src={`https://icons.llamao.fi/icons/protocols/${params.data.Project.toLowerCase()}`} height="20px" alt="" /> */}
          {window.innerWidth > 600 ?
            <div style={{width: "100%", overflow: "hidden", textOverflow:"ellipsis"}} title={params.data.Project}>{params.data.Project}</div>
            :
            null
          }
        </div>
    );
  }
  const projectCellOnAddMobRenderer = (params) => {
    return (
        <div className="macroData__imgCell">
          <div style={{width: "100%", overflow: "hidden", textOverflow:"ellipsis"}}>{params.data.Project}</div>
        </div>
    );
  }
  const poolCellRenerer = (params) => {
    let replacedFees;
    
    if(params.data.poolFees){
        replacedFees = params.data.poolFees.replace(/%/g, " ").replace(" ", "");
    }
    
    return(
      <div className="macroData__imgCell">
        <img src={`https://icons.llamao.fi/icons/protocols/${params.data.Project.toLowerCase()}`} height="20px" alt="" />
        <div style={{width: "100%", overflow: "hidden", textOverflow:"ellipsis"}} title={`${params.data.Pool} ${params.data.poolFees ? `(${replacedFees})` : null}`}>
            {params.data.Pool}
            &nbsp;
            {params.data.poolFees ? `(${replacedFees})` : null}
        </div>
      </div>
    )
  }
  const chainRenderer = (params) => {

    return (
      <div className="macroData__imgCell--onlyImg">
        <img src={`https://icons.llamao.fi/icons/chains/rsz_${params.data.Chain}`} height="20px" alt="" />
      </div>
    );
  }
  const tvlRenderer = (params) => {

    return (
      <span className="macroData__alignCell">
        {formatDollarAmount(params.data.TVL, 2)}
      </span>
    )
  }
  // const apyRenderer = (params) => {

  //   return (
  //     <span className="macroData__alignCell">
  //       {truncateToDecimals(params.data.APY, 2)}%
  //     </span>
  //   )
  // }
  const apy24hrRenderer = (params) => {

    return (
      <span className="macroData__alignCell">
        {truncateToDecimals(params.data.APY24hr, 2)}%
      </span>
    );
  }
  const apy30dRenderer = (params) => {

    return (
      <span className="macroData__alignCell">
        {truncateToDecimals(params.data.APY30d, 2)}%
      </span>
    );
  }

  const [selectedTab, setSelectedTab] = useState("all");
  const [columnDefs] = useState([
    { field: 'Pool', cellRenderer: poolCellRenerer, minWidth: window.innerWidth <= 1080 ? 220 : 300, maxWidth: window.innerWidth <= 1080 ? 120 : 350},
    // { field: 'APY', sortable: true, sort:"desc", unSortIcon: true, cellRenderer: apyRenderer, maxWidth: window.innerWidth <= 1080 ? 100 : 180},
    { field: 'APY24hr', headerName: "24hr APY", sortable: true, sort: "desc", unSortIcon: true, cellRenderer: apy24hrRenderer, maxWidth: window.innerWidth <= 1080 ? 100 : 160 },
    { field: 'APY30d', headerName: "30d APY", sortable: true, unSortIcon: true, cellRenderer: apy30dRenderer, maxWidth: window.innerWidth <= 1080 ? 100 : 160 },
    { field: 'Project', cellRenderer: projectCellRenderer, minWidth: window.innerWidth <= 1080 ? 0 : 200, maxWidth: 200},
    { field: 'TVL', sortable: true, unSortIcon: true, cellRenderer: tvlRenderer, maxWidth: window.innerWidth <= 1080 ? 100 : 150},
    { field: 'Chain', cellRenderer: chainRenderer, maxWidth: 120},
  ]);
  const [columnDefsForMobile] = useState([
    // { field: 'Pool', pinned: window.innerWidth <= 1080 ? "left" : "none", cellRenderer: poolCellRenerer, minWidth: 0, maxWidth: 180},
    { field: 'Pool', cellRenderer: poolCellRenerer, minWidth: 220, maxWidth: 300},
    // { field: 'APY', sortable: true, sort:"desc", unSortIcon: true, cellRenderer: apyRenderer, maxWidth: window.innerWidth <= 1080 ? 100 : 180},
    { field: 'APY24hr', headerName: "24hr APY", sortable: true, sort: "desc", unSortIcon: true, cellRenderer: apy24hrRenderer, maxWidth: window.innerWidth <= 1080 ? 140 : 160 },
    { field: 'APY30d', headerName: "30d APY", sortable: true, unSortIcon: true, cellRenderer: apy30dRenderer, maxWidth: window.innerWidth <= 1080 ? 140 : 160 },
    { field: 'Project', cellRenderer: projectCellOnAddMobRenderer, minWidth: window.innerWidth <= 1080 ? 0 : 230, maxWidth: window.innerWidth <= 600 ? 100 : 230},
    { field: 'TVL', sortable: true, unSortIcon: true, cellRenderer: tvlRenderer, maxWidth: window.innerWidth <= 1080 ? 100 : 150},
    { field: 'Chain', cellRenderer: chainRenderer, maxWidth: 80},
  ]);

  const getAllData = async () => {
    try {
      const json = await getAllDefiLlamaPoolsData();
      const allData = json.data.data;
      
      const filteredData = allData.filter((project) => project.apyMean30d > 0 && project.tvlUsd >= 1000000);
      
      // set Top N Yield in All chains
      const topNAtAllChains = filteredData
      setTopYieldAllChains(topNAtAllChains); 
      
      // set Top N Yield in Arbitrum
      const topNAtArbiturm = filteredData.filter((project) => project.chain === "Arbitrum").slice(0, 100);
      setTopYieldArbitrum(topNAtArbiturm);
      
      // set Top N Yield in Uniswap
      const topNAtUniswap = filteredData.filter((project) => project.project === "uniswap-v3").slice(0, 100);
      setTopYieldUniswap(topNAtUniswap);
      
      const topNAtSolana = filteredData.filter((project) => project.chain === "Solana").slice(0, 100);
      setTopYieldSolana(topNAtSolana);
      
      // set Init tabel data
      const genedBoardInitData = genRowData(topNAtAllChains);
      setRowData(genedBoardInitData);

      // set Econimic Indicators
      // get Top N pool in ETH coin
      // const top10AtEthPool = filteredData.filter((project) => project.symbol.includes("ETH")).slice(0, 10);
      
      // get Top 10 pool in Stable coin
      const top10AtStableCoinPool = filteredData.filter((project) => project.stablecoin === true).slice(0, 10);

      // get Dai Savings Rate
      // const daiDsr = await getDaiSavingsRate();

      // get GLP APY
      // const findedGmxProject = topNAtArbiturm.find(project => project.symbol = "WBTC-ETH-USDC-DAI-FRAX-LINK-UNI-USDT");

      // get BrincX APY
      let brincXApy;
      const positionInitData = positionArbUsdc.fee500;
      await getTvl(positionInitData).then(async (tvlRes) => {

        const accumulatedFeesData = (await getBalanceOfTargetWallet(treasury, tvlRes.token0Price));

        await getApy(tvlRes, positionInitData.t0, accumulatedFeesData).then(apyRes => {
          brincXApy = apyRes.tvlApy;
        });
      });

      setEconomicIndicators({
        ethApy: truncateToDecimals(await getEthApy() * 100, 2),
        stableApy: truncateToDecimals(calcUseWeightAverage(top10AtStableCoinPool), 2),
        // glpApy: truncateToDecimals(findedGmxProject.apy, 2),
        uniV3Apy: truncateToDecimals(calcUseWeightAverage(topNAtUniswap), 2),
        brincXApy: truncateToDecimals(brincXApy, 2)
      });

    } catch (error) {console.log(error)}
  }

  const chgTableData = async (target) => {
    let changedData;

    if(target === "all"){
      changedData = topYieldAllChains
    } else if (target === "arb"){
      changedData = topYieldArbitrum
    } else if (target === "sol"){
      changedData = topYieldSolana
    } else if (target === "uni"){
      changedData = topYieldUniswap
    }
    
    const genChangedData = genRowData(changedData);
    setRowData(genChangedData);
    setSelectedTab(target);
  }

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <section className="macroData__area">
      <section className="economic-section">
        <h1 className='economic-section__title'>DeFi Indicators</h1>
        <div className="economic-section__dataWrap">
          <div className="economic-section__dataWrap--dataBox">
            <div className="economic-section__dataWrap--dataBox__left">
              <h1>Ethereum <span className="pointBoldText">$ETH</span></h1>
              <span>(Staking rewards)</span>
            </div>
            <h3>
              <span>APR</span>
              {economicIndicators.ethApy}
              <span>%</span>
            </h3>
          </div>
          <div className="economic-section__dataWrap--dataBox">
            <div className="economic-section__dataWrap--dataBox__left">
              <h1>Stablecoin <span className="pointBoldText">$USD</span></h1>
              <span>(USDC, USDT, DAI...)</span>
            </div>
            <h3>
              <span>APR</span>
              {economicIndicators.stableApy}
              <span>%</span>
            </h3>
          </div>
          {/* <div className="economic-section__dataWrap--dataBox">
            <div className="economic-section__dataWrap--dataBox__left">
              <h1>GMX <span className="pointBoldText">$GLP</span></h1>
              <span>
                {window.innerWidth > 600 ? 
                  "(WBTC-ETH-USDC-DAI...)"
                  :
                  "(WBTC-ETH-USDC...)"
                }
              </span>
            </div>
            <h3>
              <span>APY</span>
              {economicIndicators.glpApy}
              <span>%</span>
            </h3>
          </div> */}
          <div className="economic-section__dataWrap--dataBox">
            <div className="economic-section__dataWrap--dataBox__left">
              <h1>Uniswap V3</h1>
              <span>(Top 10 Pools by TVL)</span>
            </div>
            <h3>
              <span>APY</span>
              {economicIndicators.uniV3Apy}
              <span>%</span>
            </h3>
          </div>
          <div className="economic-section__dataWrap--dataBox">
            <div className="economic-section__dataWrap--dataBox__left">
              <h1>Brinc X</h1>
              <span>(Historical APY)</span>
            </div>
            <h3>
              <span>APY</span>
              {economicIndicators.brincXApy}
              <span>%</span>
            </h3>
          </div>
        </div>
      </section>

      <section className="topYield-section">
          <div className="topYield-section__title">
            <div className="topYield-section__title--textWrap">
              <h1>Top pools by 24hr APY</h1>
              <p>(*)Pool TVL &gt; $1m</p>
            </div>
            <ul>
              <li onClick={() => chgTableData("all")} className={selectedTab === "all" && "selected"}>All Chains</li>
              <li onClick={() => chgTableData("arb")} className={selectedTab === "arb" && "selected"}>Arbitrum</li>
              <li onClick={() => chgTableData("sol")} className={selectedTab === "sol" && "selected"}>Solana</li>
              <li onClick={() => chgTableData("uni")} className={selectedTab === "uni" && "selected"}>Uniswap</li>
            </ul>
          </div>
          <div className="ag-theme-alpine-dark tableWrap" style={{height: window.innerWidth <= 1080 ? 500 : 800, maxWidth: 1130}}>
            <AgGridReact
              rowData={rowData}
              columnDefs={window.innerWidth > 600 ? columnDefs : columnDefsForMobile}
              defaultColDef={defaultColDef}
              onGridReady={(event) => event.api.setAutoGroupColumnDef() }
            >
            </AgGridReact>
        </div>
      </section>
    </section>
  )
}

export default MacroData;