import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

function LastFetchTime ({callRefrash, refrashCount}) {
  const [lastFetchTime, setLastFetchTime] = useState(0);
  const intervalIdRef = useRef(null);

  const getLastFetchTime = () => {
    setLastFetchTime(prev => prev + 60);
  }

  useEffect(() => {
    const fetchData = () => {
      getLastFetchTime();
      
      intervalIdRef.current = setInterval(getLastFetchTime, 60000);
    };

    fetchData();

    return () => {
      clearInterval(intervalIdRef.current);
      console.log("clearApiCall");
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLastFetchTime(0);
  }, [refrashCount]);
  return (
    <div 
      className='refetch-section--refetchBtn' 
      onClick={() => {
      setLastFetchTime(prev => prev = 0)
      callRefrash();
      }}>
     Updated <span> {Math.floor(lastFetchTime / 60)} mins</span> ago
    <img src={require("../../media/icons/ico-refrash.png").default} alt="" />
  </div>
  )
}

export default LastFetchTime