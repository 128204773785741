import { useState, useEffect } from 'react';
import { Skeleton } from "@mui/material";
import { convertToMillion, convertToThousand, truncateToDecimals } from '../../common/web3/utils'
import { getLiquidityDatas, getTodayPoolData, getVolatilityData } from "../../common/web3/api";
import VolFeeChart from './VolFeeChart';
import LiquidityChart from './LiquidityChart';
import PopupBtn from '../descriptionPopup/PopupBtn';
import { divide, multiply } from "mathjs";
import LastFetchTime from "../refetchBtn/LastFetchTime";

function AnalyticsDetail({poolData, dailyData, chartView, setChartView}) {
  const [poolInfoLoading, setPoolInfoLoading] = useState(true);
  const [poolInfo, setPoolInfo]= useState({});
  const [volatility, setVolatility] = useState(0);
  const [period, setPeriod] = useState(2);
  const [allChartData, setAllChartData] = useState([]);
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [targetPool, setTargetPool] = useState();
  const [refrashCount, setRefrashCount] = useState(0);

  const callRefrash = async (period) => {    
    await getPoolStatsData();
    await getPoolVolatility(period);
    setPeriod(2);
    setRefrashCount(prev => prev + 1);
  }

  // Pool active liquidity, cli data
  const getPoolStatsData = async () => {
    
    try {
      setPoolInfoLoading(true);

      // Total TVL / 24hr Fees / one Token Price
      const {totalValueLockedUSD, dailyFeeUSD, lastToken0Price} = await getTodayPoolData(poolData.pool);

      // 24h APY
      const poolApy = divide(multiply(dailyFeeUSD, 365), totalValueLockedUSD) * 100;

      // Active Liquidity 24h Fee, Concentrated Liquidity Index
      const {ali, cli, chartData, targetPool} = await getLiquidityDatas(null, poolData.pool, dailyData);

      setPoolInfo({
        poolTvl: convertToMillion(totalValueLockedUSD, 2, "round"),
        poolApy: poolApy.toFixed(2),
        pool24hrFees: convertToThousand(dailyFeeUSD, 2, "round"),
        oneToknePrice: truncateToDecimals(lastToken0Price, 2),
        ali,
        cli
      });

      setAllChartData(chartData);
      setTargetPool(targetPool);

      setPoolInfoLoading(false);

    } catch (error) {
      console.log(error);
      setPoolInfoLoading(false);
    }
  }

  // Price Volatility
  const getPoolVolatility = async (range) => {

    setPeriod(range);

    if(poolData){
      const targetPoolCA = poolData.pool;
  
      const volatility = await getVolatilityData(range, targetPoolCA, dailyData);
      setVolatility(volatility);
    }
    
  }

  const ALIdesc= <p>
    Fee earned by the active liquidity in the pool in the last 24 hours. <br /> 
    It represents the theoretical maximum return for the pool. <br/><br/>

    n.b ~ The fee calculation should be considered an approximation, <br/> 
    since the calculation is subject to simplifications. <br/> 
    It is determined solely on trading volume and doesn't take into consideration any impermanent loss. <br/><br/>

    Active liquidity is determined considering the perfect range where: <br/>
    Min = 24h low price <br/>
    Max = 24h high price <br/><br/>
    
    Active Liquidity 24h fee: <br/><br/>

    <div>fee tier * Volume * (LPR/ (tot. Liquidity + LPR)</div>
    --------------------------------------------- * 100 <br/>
    <div style={{paddingLeft: 120}}>VAL1</div> <br/>
    where: <br/>
    VAL1= Value of 1 unit of investment <br/>
    LPR= liquidity perfect range (for 1 unit of investment) <br/>
  </p>;

  // const CLIdesc= <p>
  //   The Concentrated liquidity Index measures the percentage of liquidity that is concentrated in a range where: <br/><br/>

  //   Min = current price - 1 standard deviation (1STD) <br />
  //   Max = current price + 1 standard deviation (1STD) <br /><br/>

  //   It's an approximate measure of the competitivity of the pool <br/><br/>

  //   Concentrated liquidity Index: <br/><br/>

  //   <div>Sum (Liquidity in range (current price - 1STD, current price + 1STD))</div>
  //   --------------------------------------------- * 100 <br/>
  //   <div style={{paddingLeft: 80}}>Total liquidity in pool</div> <br/>
  // </p>;

  useEffect(() => {
      const fetchData = async () => {
        if(dailyData){
          await getPoolStatsData();
        }
      }
      
      fetchData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyData]);

  useEffect(() => {
    if(dailyData){
      getPoolVolatility(period);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, dailyData])

  useEffect(() => {
    if(allChartData) {
      setFilteredChartData(allChartData.volume);
    }
  }, [allChartData]);

  useEffect(() => {
    if(chartView === "vol"){
      setFilteredChartData(allChartData.volume)
    } else if(chartView === "fee"){
      setFilteredChartData(allChartData.fees)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartView])

  return (
    <section className="pooldeviation-section__contents">
      <section className="pooldeviation-section--top">
        <div className="pooldeviation-section__contents--dataWrap">
          <div className="pooldeviation-section__contents--dataWrap--inner">
            <div className="pooldeviation-section__contents--dataWrap--inner__data">
              <h1>Pool APY(24 hr)</h1>
              <h3>
                {poolInfoLoading ? 
                  <Skeleton variant='text' sx={{backgroundColor: "rgba(255,255,255,0.2)", minWidth: 60, minHeight: 22}} /> 
                    : 
                  `${poolInfo?.poolApy}%`
                }
              </h3>
            </div>

            <div className="pooldeviation-section__contents--dataWrap--inner__data">
              <h1>
                Fees(24 hr)
              </h1>
              <h3>
                {poolInfoLoading ? 
                  <Skeleton variant='text' sx={{backgroundColor: "rgba(255,255,255,0.2)", minWidth: 60, minHeight: 22}} /> 
                    : 
                  `$${poolInfo?.pool24hrFees} k`
                }
              </h3>
            </div>

            <div className="pooldeviation-section__contents--dataWrap--inner__data">
              <h1>
                Pool TVL
              </h1>
              <h3>
                {poolInfoLoading ? 
                  <Skeleton variant='text' sx={{backgroundColor: "rgba(255,255,255,0.2)", minWidth: 60, minHeight: 22}} /> 
                    : 
                  `$${poolInfo?.poolTvl} m`
                }
              </h3>
            </div>

            <div className="pooldeviation-section__contents--dataWrap--inner__data">
              <div className="volatility--inner">
                <h1>Price Volatility</h1>
                <div className='period--btns'>
                  <button className={period === 2 ? "period-selected" : null} onClick={() => getPoolVolatility(2)}>24h</button>
                  <button className={period === 7 ? "period-selected" : null} onClick={() => getPoolVolatility(7)}>7d</button>
                  <button className={period === 30 ? "period-selected" : null} onClick={() => getPoolVolatility(30)}>30d</button>
                </div>
              </div>
              <h3>
                {poolInfoLoading ? 
                  <Skeleton variant='text' sx={{backgroundColor: "rgba(255,255,255,0.2)", minWidth: 60, minHeight: 23}} /> 
                    : 
                  `${truncateToDecimals(Number(volatility), 2) } %`
                }
              </h3>
            </div>

            <div className="pooldeviation-section__contents--dataWrap--inner__data">
              <h1>Active Liquidity Index <PopupBtn title="Active Liquidity Index" contents={ALIdesc} /></h1>
              <h3>
                {poolInfoLoading ? 
                  <Skeleton variant='text' sx={{backgroundColor: "rgba(255,255,255,0.2)", minWidth: 60, minHeight: 23}} /> 
                    : 
                  `${truncateToDecimals(Number(poolInfo?.ali), 2)} %`
                }
              </h3>
            </div>
          </div>
        </div>

        <div className="pooldeviation-section__contents--chartWrap">
          <div className="chartBtns onmob-flex-show">
            <button 
              className={chartView === "vol" ? "clicked" : null} 
              onClick={() => {
                setChartView("vol")
              }}
            >
              Volume
            </button>
            <button 
              className={chartView === "liq" ? "clicked" : null} 
              onClick={() => {
                setChartView("liq")
              }}
            >
              Liquidity
            </button>
            <button 
              className={chartView === "fee" ? "clicked" : null} 
              onClick={() => {
                setChartView("fee")
              }}
            >
              Fees
            </button>
          </div>

          {/* Chart */}
          {(chartView === "liq" && targetPool) && !poolInfoLoading ? <LiquidityChart targetPool={targetPool} /> : null}
          {((chartView === "vol" || chartView === "fee") && !poolInfoLoading) ? <VolFeeChart filteredChartData={filteredChartData}/> : null}
        </div>
      </section>
      <section className="refetch-section">
        <div className="refetch-section--period-column">
          <LastFetchTime callRefrash={callRefrash} refrashCount={refrashCount}/>
        </div>
      </section>
    </section>
  )
}

export default AnalyticsDetail