import React from "react";
import { Link } from "react-router-dom";
import { brincxdiscord, brincxGitbook, brincxGithub, brincxTelegram, brincxTwitter, brincxYoutube } from "../web3/address";
import "./footer.scss";

function HomeFooter() {
  // const [, setOpen] = useState(false);

  const currentYear = new Date().getFullYear();

  const imgType = {
    width: "102px",
    //imageRendering: "-webkit-optimize-contrast",
    // transform: "translateZ(0)",
    backfaceVisibility: "hidden",
  };
  return (
    <>
      <footer id="footer">
        <div className="footer__inner">
          <article className="foot__top__area mob__foot__top__area">
            <div>
              <div className="logo">
                <Link to="/" className="header__logo__link">
                  <img
                    src={require("../../media/images/brincx_logo_v2_white.svg").default}
                    alt="brinc logo icon"
                    style={imgType}
                  />
                </Link>
              </div>
              <p className="foot__top--txt">Strategies and analytics for #RealAPY</p>
              {/* <div className="foot__sns__link">
                <button
                  type="button"
                  className="sns__link"
                  onClick={() =>
                    window.open(
                      "https://discord.com/invite/QFFrBu68rp",
                      "_blank"
                    )
                  }
                >
                  <img
                    src={require("../../media/icons/ic-Discord-sm.svg").default}
                    alt="Discord icon"
                  />
                </button>
                <button
                  type="button"
                  className="sns__link"
                  onClick={() =>
                    window.open("https://twitter.com/BrincFi", "_blank")
                  }
                >
                  <img
                    src={
                      require("../../media/icons/ico-twitter-sm.svg").default
                    }
                    alt="twitter icon"
                  />
                </button>
                <button
                  type="button"
                  className="sns__link"
                  onClick={() =>
                    window.open("https://medium.com/@brinc.fi", "_blank")
                  }
                >
                  <img
                    src={require("../../media/icons/ico-medium.svg").default}
                    alt="medium icon"
                  />
                </button>
                <button
                  type="button"
                  className="sns__link"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCw8CF3-sXihmGB5iyR3_5kA",
                      "_blank"
                    )
                  }
                >
                  <img
                    src={require("../../media/icons/ico-youtube.svg").default}
                    alt="youtube icon"
                  />
                </button>
              </div> */}
            </div>
            <div className="link__list__box link__list__box--web">
              <ul className="link__list">
                <li className="link__list--item">
                  <p className="link__list--tit">Developers</p>
                </li>
                <li className="link__list--item">
                  <button
                    className="link__list--link"
                    onClick={() =>
                      window.open(
                        brincxGitbook,
                        "_blank"
                      )
                    }
                  >
                    Gitbook
                  </button>
                </li>
                <li className="link__list--item">
                  <button
                    className="link__list--link"
                    onClick={() =>
                      window.open(brincxGithub, "_blank")
                    }
                  >
                    Github
                  </button>
                </li>
                {/* <li className="link__list--item">
                  <button
                    className="link__list--link"
                    onClick={() =>
                      window.open("https://app.brinc.fi/analytics", "_blank")
                    }
                  >
                    Analytics
                  </button>
                </li>
                <li className="link__list--item">
                  <NavLink
                    to="/Governance"
                    className="link__list--link"
                    onClick={() => setOpen(false)}
                  >
                    Governance
                  </NavLink>
                </li> */}
              </ul>
              <ul className="link__list">
                <li className="link__list--item">
                  <p className="link__list--tit">Community</p>
                </li>

                <li className="link__list--item">
                  <button
                    className="link__list--link"
                    onClick={() =>
                      window.open(
                        brincxdiscord,
                        "_blank"
                      )
                    }
                  >
                    Discord
                  </button>
                </li>
                <li className="link__list--item">
                  <button
                    className="link__list--link"
                    onClick={() =>
                      window.open(brincxTwitter, "_blank")
                    }
                  >
                    Twitter
                  </button>
                </li>
                <li className="link__list--item">
                  <button
                    className="link__list--link"
                    onClick={() =>
                      window.open(brincxTelegram, "_blank")
                    }
                  >
                    Telegram
                  </button>
                </li>
                <li className="link__list--item">
                  <button
                    className="link__list--link"
                    onClick={() =>
                      window.open(
                        brincxYoutube,
                        "_blank"
                      )
                    }
                  >
                    Youtube
                  </button>
                </li>
              </ul>
            </div>
          </article>
          <article className="foot__bom__area">
            <ul className="link__list">
              <li className="link__list--hidden-link">
                {/* <NavLink
                  to="/Service"
                  className="link__list--txt"
                  onClick={() => setOpen(false)}
                > */}
                  Terms of serivces
                {/* </NavLink> */}
              </li>
              <li className="link__list--hidden-link">
                {/* <NavLink
                  to="/PrivacyPolicy"
                  className="link__list--txt"
                  onClick={() => setOpen(false)}
                > */}
                  Privacy policy
                {/* </NavLink> */}
              </li>
              <li className="link__list--hidden-link">
                {/* <NavLink
                  to="/CookiePolicy"
                  className="link__list--txt"
                  onClick={() => setOpen(false)}
                > */}
                  Cookie policy
                {/* </NavLink> */}
              </li>
            </ul>
            <p className="copyright">© BrincX {currentYear}. All rights reserved.</p>
          </article>
        </div>
      </footer>
    </>
  );
}

export default HomeFooter;
