
/* Pool address */
export const arb_usdc500PoolCA = "0xcda53b1f66614552f834ceef361a8d12a0b8dad8";
export const arb_usdc3000PoolCA = "0x81c48d31365e6b526f6bbadc5c9aafd822134863";
export const arb_usdc10000PoolCA = "0xa8328bf492ba1b77ad6381b3f7567d942b000baf";
export const eth_arb500PoolCA = "0xc6f780497a95e246eb9449f5e4770916dcd6396a";
export const eth_arb3000PoolCA = "0x92c63d0e701caae670c9415d91c474f686298f00";
export const eth_arb10000PoolCA = "0x92fd143a8fa0c84e016c2765648b9733b0aa519e";


/* Uniswap address */
export const uniswapV3PositionCA = "0xC36442b4a4522E871399CD717aBDD847Ab11FE88";
export const arbiscanDefualt = "https://arbiscan.io//address/";


/* Token address */
export const arbTokenCA = "0x912ce59144191c1204e64559fe8253a0e49e6548";
export const usdcTokenCA = "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8";
export const ethTokenCA = "0x82af49447d8a07e3bd95bd0d56f35241523fbab1";


/* Team address */
export const treasury = "0x30d5D30e43b6167a345f3D71d61673464e16d711";
export const arb_usdc500InvestmentInfo = "https://arbiscan.io/tx/0x287403dbabc7791d60b8dc4e22ac98fab73b396615b78bb393f1d36cd19df537";
export const arb_usdc3000InvestmentInfo = "https://arbiscan.io/tx/0x59d1210b4dec650ce90b03a772fdd0d7c68ad70b1a25dacef6eb674cfeed10be";


/* Mainnet address */
export const makerMCDPotCA = "0x197e90f9fad81970ba7976f33cbd77088e5d7cf7";


/* arbitrum Public RPC address */
export const rpc1 = "https://arbitrum.blockpi.network/v1/rpc/public";
export const rpc2 = "https://arb-mainnet-public.unifra.io";
export const rpc3 = "https://arb1.arbitrum.io/rpc";
export const rpc4 = "https://arbitrum-one.public.blastapi.io";


/* eth main net Public RPC address */
export const ethRpc1 = "https://ethereum.blockpi.network/v1/rpc/public";
export const ethRpc2 = "https://eth.llamarpc.com";


/* Subgraph address */
export const sgUniswapV3 = "https://api.thegraph.com/subgraphs/name/messari/uniswap-v3-arbitrum";
export const sgUniswapV3One = "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one";
export const sgMinimal = "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-minimal";
export const newSgMinimal = "https://api.thegraph.com/subgraphs/name/graph-buildersdao/univ3-arbitrum-minimal"
export const sgDev = "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-dev";
export const sgOneBlocks = "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks";


/* DefiLlama endpoint address */
export const defiLlamaApiUrl = "https://yields.llama.fi";


/* BrincX community address */
export const brincxGitbook = "https://brincx.gitbook.io/overview";
export const brincxGithub = "https://github.com/brinc-finance";
export const brincxdiscord = "https://discord.com/invite/QFFrBu68rp";
export const brincxTwitter = "https://twitter.com/Brinc_X";
export const brincxTelegram = "https://t.me/Brinc_X";
export const brincxYoutube = "https://www.youtube.com/channel/UCw8CF3-sXihmGB5iyR3_5kA";
export const deepLink = "https://metamask.app.link/dapp/brincx.io/"

/* Survey for BrincX Vault close beta join */
export const surveyUrl = "https://forms.gle/gmahbvA5QJjwHTnB9";

export const ARBITRUM_CHAINID = 42161;
export const ARBITRUM_CHAINID_HEX = "0xa4b1";