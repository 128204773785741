import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import './media/css/common.scss'
// import Home from './pages/Home/home'
import Analytics from './pages/Analytics/Analytics';
import Generator from './pages/Generator/Generator';
import Service from './pages/Service/Service'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import CookiePolicy from './pages/CookiePolicy/CookiePolicy'
import ScrollToTop from './components/scrollRestoration/ScrollRestoration'
import TopButton from './components/topButton/topButton'
import Error from './pages/Error/Error'
import HomeHeader from './common/header/header';
import HomeFooter from './common/footer/footer';
import MacroData from './pages/MacroData/MacroData';
import { Helmet } from 'react-helmet';


function App() {

  return( 
    <BrowserRouter>
      <Helmet>
        <title>Generative DeFi</title>
        <meta name="description" content="Generative DeFi" />
      </Helmet>
      <HomeHeader />
      <ScrollToTop />
      <TopButton />
      <Switch>
        {/* <Route path="/" exact component={Home}></Route> */}
        <Route path="/" exact component={MacroData}></Route>
        <Route path="/Strategies" component={Generator}></Route>
        <Route path="/Analytics" component={Analytics}></Route>
        <Route path="/MacroData" component={MacroData}></Route>
        <Route path="/Service" component={Service}></Route>
        <Route path="/PrivacyPolicy" component={PrivacyPolicy}></Route>
        <Route path="/CookiePolicy" component={CookiePolicy}></Route>
        <Route path="/Error" exact component={Error}></Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <HomeFooter />
    </BrowserRouter>
  )
}

export default App;
