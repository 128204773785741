import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./header.scss";

function Nav() {
  const itemLink = "menu__item";
  const [, setOpen] = useState(false);

  return (
    <>
      <ul className="home__nav">
    
      <li className="nav__item">
          <NavLink
            to="/MacroData"
            activeClassName="selected"
            className={itemLink}
            onClick={() => setOpen(false)}
          >
            <p className="menuButton openMenuButton">
              <span>Macro</span> <br />
              <span className="coming-soon"></span>
            </p>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink
            to="/Analytics"
            activeClassName="selected"
            className={itemLink}
            onClick={() => setOpen(false)}
          >
            <p className="menuButton openMenuButton">
              <span>Analytics</span> <br />
              <span className="coming-soon"></span>
            </p>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink
            to="/Strategies"
            activeClassName="selected"
            className={itemLink}
            onClick={() => setOpen(false)}
          >
            <p className="menuButton openMenuButton">
              <span>Strategies</span> <br />
              <span className="coming-soon"></span>
            </p>
          </NavLink>
        </li>
       
        {/* <li className="nav__item" onClick={() => window.open("https://test-brincx.netlify.app")}>
          <p className="menuButton openMenuButton">
            <span >Vault Test</span> <br />
            <span className="coming-soon"></span>
          </p>
        </li> */}
      </ul>
    </>
  );
}

export default Nav;
