import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nav from "./nav";
import "./header.scss";
import MobMenu from "../header/mobMenu";
import { brincxdiscord, brincxTwitter } from "../web3/address";
import { useWeb3React } from "@web3-react/core";
import { handleConnectWallet, shortAddress } from "../web3/utils";

function HomeHeader() {
  const homeHeader = "home__header";
  const mobMenuBtn = "mob__menuBtn";
  const [mobNavMenu, setMobNavMenu] = useState(mobMenuBtn);
  const buttonClick = () => {
    setMobNavMenu(!mobNavMenu);
    // document.body.style.overflowY = `${!mobNavMenu ? "scroll" : "hidden" }`
  };
  const { account, active, activate } = useWeb3React();
  
  useEffect(() => {
    const handleAutoConnect = async () => {
      const isMMUnlocked = await window.ethereum._metamask.isUnlocked();

      if(isMMUnlocked){
        handleConnectWallet(active, activate);
      }
    }
    handleAutoConnect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imgType = {
    height: "20px",
    backfaceVisibility: "hidden"
  };

  return (
    <>
      <header
        className={`${!mobNavMenu ? homeHeader + " active" : homeHeader}`}
        style={{zIndex: 99}}
      >
        <div className="container header--container">
          <div className="header--container__leftWrap">
            <Link to="/" className="header__logo__link">
              <img
                src={require("../../media/images/brincx_logo_v2_white.svg").default}
                alt="brinc logo icon"
                style={imgType}
              />
            </Link>
            <Nav />
          </div>

          <div className="menu__btn__box">
            <ul className="menu__btn__box--snsWrap">
              <li onClick={() => window.open(brincxdiscord)}>
                <img src={require("../../media/icons/ico_discode_white.png").default} alt=""/>
              </li>
              <li onClick={() => window.open(brincxTwitter)}>
                <img src={require("../../media/icons/ico_twitter_white.png").default} alt=""/>
              </li>
            </ul>
            <button className={`menu__btn__box--walletBtn`} onClick={() => handleConnectWallet(active, activate)}>
              {!active ?
                "Connect Wallet"
                :
                <span style={{fontWeight: "bold", color: "#959595"}}>{shortAddress(account)}</span>
                 
              }
            </button>
            <div
              className={`${!mobNavMenu ? mobMenuBtn + " open" : mobMenuBtn}`}
              onClick={buttonClick}
            >
              <span className="mob__menuBtn--item item01"></span>
              <span className="mob__menuBtn--item item02"></span>
              <span className="mob__menuBtn--item item03"></span>
              <span className="mob__menuBtn--item item04"></span>
            </div>
          </div>
        </div>
        <div className="mob__menu__box">
          <MobMenu buttonClick={buttonClick} />
        </div>
      </header>
    </>
  );
}

export default HomeHeader;
