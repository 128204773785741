import "./generator.scss";
import GeneratorStats from "../../components/generatorStats/GeneratorStats";
import { useRecoilValue } from "recoil";
import { arbUsdcDataAtom } from "../../store/Atom";

function Generator () {
  const positionArbUsdc = useRecoilValue(arbUsdcDataAtom);
  
  return(
    <main id="main">
      <section className="generator__area">
        <GeneratorStats positionInitData={positionArbUsdc.fee500} />
      </section>
    </main>
  )
}

export default Generator