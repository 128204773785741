import Moralis from "moralis";
import dayjs from 'dayjs'
import { JSBI } from "@uniswap/sdk";
import { Token } from '@uniswap/sdk-core'
import { ethers, utils } from "ethers";
import { round } from "mathjs";
import numbro from "numbro";
import { InjectedConnector } from "@web3-react/injected-connector";
import { isMobile } from "react-device-detect";
import { deepLink } from "./address";

export const Q96 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(96));
export const MAX = ethers.BigNumber.from(2).pow(128).sub(1).toString();

// Capitalize String
export const capitalize = (str) => {
  let words = [];
  words = str.split("-");
  for(let i = 0; i <= words.length - 1; i++){
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  words = words.join(" ");

  return words;
}

export const numberWithThousandSeparators = (n, decimals = 2) => {
  return Intl.NumberFormat(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(n);
};

// Discard up to n decimal places
export const truncateToDecimals = (amount, n) => {
  try {
    if(!amount) return "0.00";
    if(!amount.toString().includes('.')) return amount;

    amount = amount.toString();
    const int = amount.split('.')[0];
    const decimals = amount.split('.')[1].slice(0, n);
    
    if(n === 0){
      return `${int}`
    } else {
      return `${int}.${decimals}`;
    }
  } catch (error) {
    console.log(error);    
  }
};

// convert to thousand
export const convertToThousand = (amount, decimal, method) => {
  try {
    const parsedAmount = parseInt(amount);
    let convertThousand = parsedAmount / 1000;

    if(method === "round"){
      convertThousand = (Math.round(convertThousand * 100) / 100).toFixed(2);
    }

    const result = truncateToDecimals(convertThousand, decimal);

    return result;

  } catch (error) {
    console.log(error);
    return amount;   
  }
}

// convert to million
export const convertToMillion = (amount, decimal, method) => {
  try {
    const parsedAmount = parseInt(amount);
    let convertMillion = parsedAmount / 1000000;

    if(method === "round"){
      convertMillion = (Math.round(convertMillion * 100) / 100).toFixed(2);
    }

    const result = truncateToDecimals(convertMillion, decimal);

    return result;

  } catch (error) {
    console.log(error);
    return amount;   
  }
}

export const formatDollarAmount = (num, digits = 2, round = true) => {
  if (num === 0) return '$0.00'
  if (!num) return '-'
  if (num < 0.001 && digits <= 3) {
    return '<$0.001'
  }

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num > 1000 ? 1 : digits,
    abbreviations: {
      million: 'M',
      billion: 'B',
    },
  })
}

export const formatAmount = (num, digits = 2) => {
  if (num === 0) return '0'
  if (!num) return '-'
  if (num < 0.001) {
    return '<0.001'
  }
  return numbro(num).format({
    average: true,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: 'M',
      billion: 'B',
    },
  })
}

// Moralis Init
export const runMoralis = async () => {
  await Moralis.start({
    apiKey: process.env.REACT_APP_MORALIS_API_KEY
  })
}

const getDate = async (type, range) => {
  let timestampsArray = [];

    for (let i = 0; i < range; i++) {
      const timestamp = dayjs().subtract(i, type);
      const date = dayjs(timestamp).format(`DD/MM`);
      const time = timestamp.unix();
      timestampsArray.unshift({
        date,
        time
      });
    }

  return timestampsArray;
}

export const parsePrice = (price, percent) => {

  const rounder = percent ? 2 : 4;

  if (price === 0) {
    return 0;
  }
  else if (price > 1000000) {
    return parseInt(price);
  }
  else if (price > 1) {
    return round(price, 2);
  }
  else {
    const m = -Math.floor( Math.log(Math.abs(price)) / Math.log(10) + 1);
    return round(price, m + rounder);

  }
}

export function unixToDate(unix, format = 'YYYY-MM-DD') {
  return dayjs.unix(unix).format(format);
}

// Daily epoch time output
export const getDailyUnixTimeArr = () => {
  const utcCurrentTime = dayjs()
  const t1 = utcCurrentTime.subtract(1, 'day').startOf('minute').unix()
  const t2 = utcCurrentTime.subtract(2, 'day').startOf('minute').unix()
  const tWeek = utcCurrentTime.subtract(1, 'week').startOf('minute').unix()
  return [t1, t2, tWeek]
}

// Week, month epoch time output
export const getUnixTimeArr = async () => {
  runMoralis();
  const days = await getDate("day", 30);
  const weeks = await getDate("week", 5);
  const months = await getDate("month", 12);

  const unixTimeObj = {
    days,
    weeks,
    months
  }

  return unixTimeObj
}

export const get2DayChange = (valueNow, value24HoursAgo) => {
  // get volume info for both 24 hour periods
  console.log(valueNow)
  console.log(value24HoursAgo)
  const currentChange = parseFloat(valueNow) - parseFloat(value24HoursAgo)
 
  console.log(currentChange)

  return currentChange
}

// Logic for Volatility
export const variance = function (arr, std_type) {
  var v = 0;
  var arr_length = arr.length;
  var this_mean = mean(arr);

  if (arr_length > 1) {
      for (var i = 0; i < arr_length; i++) {

          v = v + (arr[i] - this_mean) * (arr[i] - this_mean);
      }

      if (std_type === 's') {

          return v / (arr_length - 1);
      }
      else {

          return v / arr_length;
      }
  }
  else {

      return 0;
  }
}

// Logic for Volatility
export const mean = function (arr) {
  var sum = 0;
  var length = arr.length;
  for (var i = 0; i < length; i++) {

      sum = sum + arr[i]
  }

  var mean = sum / length;
  return mean;

}

// Sum a arithmetic sequence in an array
export const sumArray = (arr) => {
  return arr.reduce((a, b) => Number(a) + Number(b), 0);
}

export const calcLiquidity0 = (sqrtA, sqrtB, amount, decimals) => {
 
  const lowest = Math.min(sqrtA, sqrtB);
  const highest = Math.max(sqrtA, sqrtB);
  return amount / (((highest - lowest) / highest / lowest) / Math.pow(10, decimals));
}

export const calcLiquidity1 =(sqrtA, sqrtB, amount, decimals) => {   

  const lowest = Math.min(sqrtA, sqrtB);
  const highest = Math.max(sqrtA, sqrtB);
  return amount / ((highest - lowest) / Math.pow(10, decimals));
}

// Get the fees for liquidity generated in the last 24 hours
export const calc24HrFee = (priceData, pool, target24hVolumeUSD, target24HFeesUSD) => {

  if (priceData && pool) {
    const priceToken0usd = parseFloat(Number(target24hVolumeUSD)) / parseFloat(priceData.volumeToken0)
    const priceToken1usd = parseFloat(Number(target24hVolumeUSD)) / parseFloat(priceData.volumeToken1)
  
    const decimal0 = pool.token0.decimals;
    const decimal1 = pool.token1.decimals;
    const decimal = decimal1 - decimal0;
  
    const sqrtHigh = Math.sqrt(parseFloat(priceData.high));  //SqrtA
    const sqrtLow = Math.sqrt(parseFloat(priceData.low)); //SQRTB
    const sqrtClose = Math.sqrt(parseFloat(priceData.close)); // sqrt
  
    const target = 1;
    const delta = target / (((sqrtClose - sqrtLow) * priceToken0usd) + (((1 / sqrtClose) - (1 / sqrtHigh)) * priceToken1usd));
    const amount1 = delta * (sqrtClose - sqrtLow);
    const amount0 = delta * ((1 / sqrtClose) - (1 / sqrtHigh));
  
    const sqrtHighDec = Math.sqrt(priceData.high * Math.pow(10, decimal) );
    const sqrtLowDec = Math.sqrt(priceData.low * Math.pow(10, decimal) );
    const sqrtCloseDec = Math.sqrt(priceData.close * Math.pow(10, decimal));
  
    let liquidity;
    const lowest = Math.min(sqrtHighDec, sqrtLowDec);
    const highest = Math.max(sqrtHighDec, sqrtLowDec);

    
    if (sqrtCloseDec <= lowest) {
      
      liquidity =  calcLiquidity0(lowest, highest, amount0, decimal0);
      
    } else if (sqrtCloseDec > lowest && sqrtCloseDec < highest) {
  
      const liquidity0 = calcLiquidity0(sqrtCloseDec, highest, amount0, decimal0);
      const liquidity1 = calcLiquidity1(lowest, sqrtCloseDec, amount1, decimal1);
      liquidity = liquidity0 < liquidity1 ? liquidity0 : liquidity1;
  
    } else {
      liquidity =  calcLiquidity1(lowest, highest, amount1, decimal1);
    }
    
    const fee = (parseFloat(target24HFeesUSD) * (liquidity / (liquidity + parseFloat(+priceData.liquidity)))) * 100;
    return isNaN(fee) ? 0 : fee;
  }
  else {
    return 0;
  }
}

export const logWithBase = (y, x) => {
  return Math.log(y) / Math.log(x);
}

// Using tick data to get a reference price
export const getTickFromPrice = (val, pool, baseSelected) => {

  const decimal0 = baseSelected && baseSelected === 1 ? parseInt(pool.data.pools[0].token1.decimals) : parseInt(pool.data.pools[0].token0.decimals);
  const decimal1 = baseSelected && baseSelected === 1 ? parseInt(pool.data.pools[0].token0.decimals) : parseInt(pool.data.pools[0].token1.decimals);

  const valToLog = parseFloat(val) * Math.pow(10, (decimal0 - decimal1));
  const tickIDXRaw = logWithBase(valToLog,  1.0001);
  return round(tickIDXRaw, 0);
}

export const getPriceFromTick = (tick, token0Decimal, token1Decimal) => {
  let price0 = (1.0001 ** tick) / (10 ** (token1Decimal - token0Decimal));
  let price1 = 1 / price0;

  return {
    price0: parseFloat(price0.toFixed(5)),
    price1: parseFloat(price1.toFixed(5))
  }
}

const getTickDiff = (currentTick, refPrices, poolSelected) => {

  const refTicks = refPrices.map(d => {
    return !d || d === 0 ? 0  : getTickFromPrice(d, poolSelected) * -1;
  });

  let refTick = 0;

  refTicks.forEach(d => {
    refTick = d !== 0 && Math.abs(currentTick - d) > refTick ? Math.abs(currentTick - d) : refTick
  });

  return refTick;
}

// Logic for CLI
export const filterTicks = (data, currentTick, refPrices, poolSelected, zoomLevel) => {

  const delta = getTickDiff(currentTick, refPrices, poolSelected);
  const zoom = zoomLevel ? parseFloat(zoomLevel) : 1;
  const min = parseInt(parseFloat(currentTick) - (1 * delta * zoom));
  const max = parseInt(parseFloat(currentTick) + (1 * delta * zoom));

  const filteredData = [...data].filter((d, i) => {
    return ( parseFloat(d.index) + parseFloat(d.width) > min && parseFloat(d.index) < max );
  });
  
  if (filteredData && filteredData.length > 1) {

    const firstRecord = {...filteredData[0]};
    const lastRecord = {...filteredData[(filteredData.length - 1)]};
    const firstTick = Math.max(min, firstRecord.tickIdx);
    
    firstRecord.tickIdx = parseInt(firstTick);
    firstRecord.tickIdx0 = firstTick;
    firstRecord.tickIdx1 = firstTick * -1;
    firstRecord.width = filteredData[1].tickIdx0 - firstTick;
    lastRecord.width = max - lastRecord.tickIdx0;
    lastRecord.tickIdx1 = parseFloat(lastRecord.tickIdx0 * -1) - (max - lastRecord.tickIdx0);

    filteredData[0] = firstRecord;
    filteredData[(filteredData.length - 1)] = lastRecord;
    return filteredData;
  }
  else {
    return data; 
  }

}

//  Calculatie CLI
export const calcCLI = (data, normStd, pool, basePrice) => {

  if (data && data.length) {

    const CLIdata = [...data];

    const percent = (100 - normStd) / 100;
    const price = basePrice * percent;
  
    const liquidity = Array.from(CLIdata, d => d.liquidity);
    const totalSum = sumArray(liquidity);
  
    const filteredData = filterTicks(CLIdata, parseFloat(CLIdata[0].pool.tick), [price], pool, 1); 
    const filteredLiquidity = Array.from(filteredData, d => d.liquidity);
    const filteredSum = sumArray(filteredLiquidity);
  
    const cli = (filteredSum / totalSum) * 100; 
    return {cli, filteredData};
  }
  else {
    return 0;
  }
  
}

// Create a Pool data object
export const genLiquidityData = (data, feeTier) => {
  let cumsum = 0;
  const multiplier = 1 + (feeTier / 500000);
  if (data && data.length && data.length > 0) {
    let len = data.length;

  return data.map((d, i) => {

    cumsum += parseFloat(d.liquidityNet);
    const T = cumsum * Math.sqrt(d.price0);
    const H = cumsum / Math.sqrt(d.price1 * multiplier);
    const nextRecord = Math.min((len - 1), (i + 1));
    const width = Math.abs(parseInt(data[nextRecord].index)  - parseInt(d.index))

    return {
      ...d, 
      decimal: parseInt(d.pool.token0.decimals) - parseInt(d.pool.token1.decimals),
      liquidity: cumsum,
      width: parseFloat(width),
      tvlAmount0: ((Math.pow(cumsum, 2) / T) - H) / Math.pow(10, d.pool.token0.decimals),
      tvlAmount1: ((Math.pow(cumsum, 2 ) / H) - T) / Math.pow(10, d.pool.token1.decimals),
      price0: parseFloat(d.price0),
      price1: parseFloat(d.price1),
      price0N: Math.pow(1.0001, d.tickIdx) / Math.pow(10, (d.pool.token1.decimals - d.pool.token0.decimals)),
      price1N: Math.pow(1.0001, d.tickIdx * -1) / Math.pow(10, (d.pool.token0.decimals - d.pool.token1.decimals)),
      tickIdx0: parseInt(d.index),
      tickIdx1: parseInt((d.index * -1) - width)
    }
  });
  }
}

export const roundToNearestTick = (value, feeTier, baseDecimal, quoteDecimal) => {
  const divider = feeTier / 50;
  const valToLog = parseFloat(value) * Math.pow(10, parseInt(+baseDecimal) - parseInt(+quoteDecimal));
  const tickIDXRaw = logWithBase(valToLog,  1.0001);
  const tickIDX = round(tickIDXRaw / divider, 0) * divider;
  const tick = Math.pow(1.0001, tickIDX) / Math.pow(10, parseInt(baseDecimal) - parseInt(quoteDecimal));

  const m = -Math.floor( Math.log(tick) / Math.log(10) + 1);
  return round(tick, m + 6);
}

export const genToken = (chainId = 1, address, decimals) => {
  const token = new Token(chainId, address, decimals);
  return token;
}

export const FEE_TIER_TO_TICK_SPACING = (feeTier) => {
  switch (feeTier) {
    case '10000':
      return 200
    case '3000':
      return 60
    case '500':
      return 10
    case '100':
      return 1
    default:
      throw Error(`Tick spacing for fee tier ${feeTier} undefined.`)
  }
}

// Use a weighted average calculation
export const calcUseWeightAverage = (dataArr) => {
  const totalTvl = dataArr.reduce((accumulator, currentValue) => accumulator + currentValue.tvlUsd, 0);

  const result = dataArr.reduce((accumulator, currentValue) => accumulator + (currentValue.tvlUsd / totalTvl) * currentValue.apy, 0);

  return result;
}

// Dai dsr calculation
export const calcFee = (rate) => {
  const result = parseFloat(utils.formatUnits(rate, 27)) ** (60*60*24*365) * 1 - 1;
  return result;
}

// Create top yield pool table row data form
export const genRowData = (arr) => {
  const tempArr = [];

  arr.map((item) => tempArr.push({
    Pool: item.symbol,
    Project: capitalize(item.project),
    Chain: item.chain.toLowerCase(),
    TVL: item.tvlUsd,
    APY: item.apy,
    APY24hr: item.apyBase,
    APY30d: item.apyMean30d,
    poolFees: item.poolMeta
  }));

  return tempArr;
}


// Connect Web3 Wallet
export const injected = new InjectedConnector();

// split user's address
export const shortAddress = (address) => {
  const forth = address.slice(0, 5);
  const rear = address.slice(-4);
  return `${forth}...${rear}`
}

export const handleConnectWallet = async (active, activate) => {
  if(active){

  } else {
    if(isMobile){
      activate(injected, (error) => {
        // deep link
        window.open(deepLink);
      });
    } else {
      activate(injected, (error) => {
        console.log(error)
      });
    }
  }
};


export const getTokenAmounts = async (liquidity, sqrtPriceX96, currentTick, tickLow, tickHigh, token0Decimal, token1Decimal) => {
  let sqrtRatioA = parseFloat(Math.sqrt(1.0001**tickLow).toFixed(18));
  let sqrtRatioB = parseFloat(Math.sqrt(1.0001**tickHigh).toFixed(18));
  let sqrtPrice = sqrtPriceX96 / parseInt(Q96.toString());
  let amount0wei = 0;
  let amount1wei = 0;
  if(currentTick <= tickLow){
      amount0wei = Math.floor(liquidity*((sqrtRatioB-sqrtRatioA)/(sqrtRatioA*sqrtRatioB)));
  }
  if(currentTick > tickHigh){
      amount1wei = Math.floor(liquidity*(sqrtRatioB-sqrtRatioA));
  }
  if(currentTick >= tickLow && currentTick < tickHigh){ 
      amount0wei = Math.floor(liquidity*((sqrtRatioB-sqrtPrice)/(sqrtPrice*sqrtRatioB)));
      amount1wei = Math.floor(liquidity*(sqrtPrice-sqrtRatioA));
  }
  
  let amount0Human = (amount0wei/(10**token0Decimal)).toFixed(2);
  let amount1Human = (amount1wei/(10**token1Decimal)).toFixed(2);

  return {amount0wei, amount1wei, amount0Human, amount1Human}
};
