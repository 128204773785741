import React from 'react'
import styled from "styled-components";

const Tit=styled.h2`
    padding-bottom: 32px;
    font-family: var(--font2);
    font-style: normal;
    font-weight: bold;
    font-size: 54px;
    line-height: 64px;
    letter-spacing: 0.37px;
    color: #FFFFFF;
    @media screen and (max-width: 1000px) {
        padding-bottom: 24px;
        font-size: 36px;
        line-height: 49px;
    }
`
const Cont=styled.p`
    padding-bottom: 44px;
    font-family: var(--font1);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    @media screen and (max-width: 1000px) {
        padding-bottom: 56px;
        font-size: 15px;
        line-height: 22px;
    }
`
const SubCont=styled.h3`
    padding-bottom: 24px;
    font-family: 'WorkSansB';
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 150%;
    letter-spacing: -0.24px;
    text-transform: uppercase;
    color: #FFFFFF;
    @media screen and (max-width: 1000px) {
        padding-bottom: 16px;
        font-size: 28px;
        line-height: 42px;
    }
`
const Txt=styled.p`
    padding-bottom: 24px;
    font-family: var(--font1);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    @media screen and (max-width: 1000px) {
        padding-bottom: 16px;
        font-size: 15px;
        line-height: 22px;
    }
`

function CookiePolicy(){
  return ( 
    <main id='main'>
      <section className="section__area section__area--comming" style={{marginTop: "100px", paddingTop: 50}}>
          <div className="container">
              <article className="section__container">
                  <Tit>COOKIE POLICY</Tit>
                  <Cont>The Brinc.fi website uses cookies.</Cont>
                  <div>
                      <SubCont>A. What is a cookie?</SubCont>
                      <Cont>A cookie is a small file that can be placed on your device that allows us to recognize and remember you. It is sent to your browser and stored on your computer’s hard drive or tablet or mobile device. When you visit our sites, we may collect information from you automatically through cookies or similar technology.</Cont>
                  </div>
                  <div>
                      <SubCont>B. How do we use cookies?</SubCont>
                      <Txt>We use cookies in a range of ways to improve your experience on our site, including:</Txt>
                      <Txt>(i) Keeping you signed in</Txt>
                      <Txt>(ii) Understanding how you use our site</Txt>
                      <Txt>(iii) Showing you products and services that are relevant to you</Txt>
                      <Txt>(iv) Provide social media features and to analyze our traffic</Txt>
                      <Txt>(v) Share information about your use with our social media, advertising and analytics partners</Txt>
                      <Cont>(vi) Analyze how our site is being used, measure the number of visitors, and display advertising</Cont>
                  </div>
                  <div>
                      <SubCont>C. What types of cookies do we use?</SubCont>
                      <Txt>We use different types of cookies, as described below:</Txt>
                      <Txt>
                          <span className="font__bold">Necessary</span> – cookies that are essential to provide you with the services you have requested. 
                          For example, these include the cookies that make it possible for you to stay logged into your account and make comments. 
                          If you set your browser to block these cookies, then these functions and services will not work for you. 
                          In particular, we won’t be able to save your preferences about cookies.
                      </Txt>
                      <Txt>
                          <span className="font__bold">Statistics</span> – cookies that measure how often you visit our sites and how you use them. 
                          We use this information to get a better sense of how our users’ engagements and to improve our sites and apps, so that users have a better experience.
                          We also use third-party cookies to help with performance.
                      </Txt>
                      <Txt>
                          <span className="font__bold">Preferences</span> – cookies that are used to recognize you and remember your preferences or settings when you return to our site, so that we can provide you with a more personalized experience.
                          For example, the information displayed may be different depending on your location.
                          A mix of first-party and third-party cookies are used.
                      </Txt>
                      <Txt>
                          <span className="font__bold">Marketing</span> – cookies that are used to collect information about your visit to our site,
                          the content you have viewed, the links you have followed and information about your browser, device and your IP address.
                          These cookies will allow us to show you advertisements which we believe may interest you.
                      </Txt>
                      <Cont>
                          The advertising techniques we use do not collect information such as your name, email address, postal address or phone number. 
                          We sometimes use information such as your IP address and browser type and also sometimes share some limited aspects of this with third parties for advertising purposes.
                      </Cont>
                  </div>
                  <div>
                      <SubCont>D. How to manage cookies?</SubCont>
                      <Txt>You can manage the use of cookies, including advertising cookies, and disable the sharing of data with partners for advertising purposes by clicking privacy settings.</Txt>
                      <Cont>
                          It is also possible to stop your browser from accepting cookies altogether by changing your browser’s cookie settings. 
                          You can usually find these settings in the “options” or “preferences” menu of your browser.
                      </Cont>
                  </div>
                  <div>
                      <SubCont>E. Contact Info</SubCont>
                      <Cont>If you have any questions concerning our Cookie Policy, you can contact our data protection officer at privacy@brinc.fi.</Cont>
                  </div>
                  <div>
                      <SubCont>F. Revision Date</SubCont>
                      <Cont>This Agreement was last updated on February 08, 2021 (“Revision Date“). If you were a user before the Revision Date, it replaces the existing Cookie Policy.</Cont>
                  </div>
              </article>
          </div>
      </section>
    </main>
  )
}

export default CookiePolicy;