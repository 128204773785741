/* eslint-disable no-unused-vars */
import Web3 from "web3";
import uniV3positionAbi from "./abiJson/uniswapV3Position.json";
import uniV3poolAbi from "./abiJson/uniswapV3Pool.json";
import makerMCDPotAbi from "./abiJson/makerMCDPot.json";
import erc20Abi from "./abiJson/erc20.json";
import { TickMath, tickToPrice } from '@uniswap/v3-sdk'
import { calc24HrFee, calcCLI, calcFee, FEE_TIER_TO_TICK_SPACING, genLiquidityData, genToken, get2DayChange, getDailyUnixTimeArr, MAX, Q96, sumArray, unixToDate, variance } from "./utils";
import { ARBITRUM_CHAINID, arbTokenCA, arb_usdc10000PoolCA, arb_usdc3000PoolCA, arb_usdc500PoolCA, defiLlamaApiUrl, ethRpc1, makerMCDPotCA, newSgMinimal, rpc1, sgOneBlocks, sgUniswapV3, treasury, uniswapV3PositionCA, usdcTokenCA } from "./address";
import { keyBy } from "lodash";
import JSBI from 'jsbi'
import axios from "axios";
import { ethers } from "ethers";
import { multiply, sum } from "mathjs";

const publicProvider = new Web3(rpc1);
const ethMainNetPublicProvider = new Web3(ethRpc1);

// Contract
const uniswapPositionContract = new publicProvider.eth.Contract(
  uniV3positionAbi,
  uniswapV3PositionCA
);

const arb_usdc500PoolContract = new publicProvider.eth.Contract(
  uniV3poolAbi,
  arb_usdc500PoolCA
);

const arb_usdc3000PoolContract = new publicProvider.eth.Contract(
  uniV3poolAbi,
  arb_usdc3000PoolCA
);

const arb_usdc10000PoolContract = new publicProvider.eth.Contract(
  uniV3poolAbi,
  arb_usdc10000PoolCA
);

const makerMCDPotContract = new ethMainNetPublicProvider.eth.Contract(
  makerMCDPotAbi,
  makerMCDPotCA
);

const arbTokenContract = new publicProvider.eth.Contract(
  erc20Abi,
  arbTokenCA
);

const usdcTokenContract = new publicProvider.eth.Contract(
  erc20Abi,
  usdcTokenCA
)  


/* ------------------------------ Helper ------------------------------ */

export const customAxios = axios.create({
  baseURL: defiLlamaApiUrl,
  headers: {
    'Content-Type': 'application/json',
  }
});

// gen Ticks
// export const liquidityByPoolId = async (poolCA) => {

//   const query =  `query Ticks ($poolCA: ID!){
//     ticks (first: 1000, where: {pool: $poolCA}, orderBy: index) {
//       index
//       liquidityGross
//       liquidityNet
//       prices
//       pool{
//         tick
//         inputTokens {
//           name
//           lastPriceUSD
//           decimals
//         }
//       }
//     }
//   }`

//   const json = await (await fetch(newSgMinimal, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify({query, variables: {poolCA}})
//   })).json();

//   return json.data.ticks;
// }

export const liquidityByPoolId = async (poolCA) => {

    const query =  `query Ticks ($poolCA: ID!){
      ticks (first: 1000, where: {pool: $poolCA}, orderBy: tickIdx) {
        tickIdx
        liquidityGross
        liquidityNet
        price0
    	  price1
        pool{
          tick
          token0Price
          token0{
            name
            decimals
          }
          token1{
            name
            decimals
          }
        }
      }
    }`
  
    const json = await (await fetch(newSgMinimal, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({query, variables: {poolCA}})
    })).json();
  
    return json.data.ticks;
  }

// get surrounding Ticks
export const fetchInitializedTicks = async (poolCA, tickIdxLowerBound, tickIdxUpperBound) => {
  try {
    let surroundingTicks = [];
    let surroundingTicksResult = [];
    let skip = 0;

    const query = `
      query surroundingTicks(
        $poolAddress: String!
        $tickIdxLowerBound: BigInt!
        $tickIdxUpperBound: BigInt!
        $skip: Int!
      ) {
        ticks(
          subgraphError: allow
          first: 1000
          skip: $skip
          where: { poolAddress: $poolAddress, tickIdx_lte: $tickIdxUpperBound, tickIdx_gte: $tickIdxLowerBound }
        ) {
          tickIdx
          liquidityGross
          liquidityNet
          price0
          price1
        }
      }
    `;

    do{

      const getTicks = await ( await fetch(newSgMinimal, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({query, variables: {poolAddress: poolCA, tickIdxLowerBound, tickIdxUpperBound, skip}})
      })).json();
    
      surroundingTicks = getTicks.data.ticks
      surroundingTicksResult = surroundingTicksResult.concat(surroundingTicks)
      skip += 1000
    } while (surroundingTicks.length > 0);

    return {ticks: surroundingTicksResult}
  } catch (error) {
    console.log(error);
  }
}

// get One Token Price
export const getOneTokenPrice = async (block, token) => {

  try {
  const query = `
    query pools ($token: ID!){
      pools(where: {id_in: [$token]} ${block ? `, block: {number: ${block}}` : ""} subgraphError: allow) {
        token1Price
      }
    }
  `;
  const oneTokenPrice = await ( await fetch(newSgMinimal, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({query, variables: {token}})
  })).json();

  const result = Number(oneTokenPrice.data.pools[0].token1Price).toFixed(5);

  return Number(result);
  } catch (error) {
    console.log(error)
  }
}

// Calculate liquidity to two token
const getTokenAmounts = async (pool, liquidity, sqrtPriceX96, tickLower, tickUpper, token0Decimal, token1Decimal) => {
  const sqrtRatioA = Math.sqrt(1.0001 ** tickLower).toFixed(18);
  const sqrtRatioB = Math.sqrt(1.0001 ** tickUpper).toFixed(18);
  // const currentTick = Math.floor(Math.log((sqrtPriceX96/Q96) ** 2)/Math.log(1.001));
  const sqrtPrice = sqrtPriceX96 / Q96;
  let amount0wei = 0;
  let amount1wei = 0;

  const token0Price = await getOneTokenPrice(null, pool);

  amount0wei = Math.floor(liquidity*((sqrtRatioB-sqrtPrice)/(sqrtPrice*sqrtRatioB)));
  amount1wei = Math.floor(liquidity*(sqrtPrice-sqrtRatioA));
  
  let amount0Human = (amount0wei/(10**token0Decimal)).toFixed(token0Decimal);
  let amount1Human = (amount1wei/(10**token1Decimal)).toFixed(token1Decimal);

  // If usdc amount 0 in out of range
  if(amount1Human <= 0){
    const usdcToArbAmount = Math.abs(amount1Human) / token0Price;
    amount0Human = amount0Human - usdcToArbAmount;
    amount1Human = 0;
  }

  const tvl = ((+amount0Human * token0Price) + +amount1Human).toFixed(0);
  const amount0Percent = (((+amount0Human * token0Price) / tvl) * 100).toFixed(0); 
  const amount1Percent = ((+amount1Human / tvl) * 100).toFixed(0);

  return {tvl, amount0Human, amount1Human, amount0Percent, amount1Percent, token0Price};
}

// get timesteamp to block
export const getBlock = async (timestamp) => {
  let result;

  const query = `query blocks {
    time:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { 
      timestamp_gt: ${timestamp}, timestamp_lt: ${Number(timestamp) + 600} 
    }) { number }}`

  try {
    const startQuery = async () => {
      const json = await ( await fetch(sgOneBlocks, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({query})
      })).json();
      result = json.data.time[0].number;
    }
    
    await startQuery();

  } catch (error) {}

   return result;
};

// get Pool info
export const getPoolData = async (poolCA, block) => {
  try {    
  const query = `
    query pools {
      pools(
        where: {id_in: ["${poolCA}"]},
        ${block ? `block: {number: ${block}} ,` : ``}
        orderBy: totalValueLockedUSD
        orderDirection: desc
        subgraphError: allow
      ) {
        id
        feeTier
        liquidity
        sqrtPrice
        tick
        token0 {
          id
          symbol
          name
          decimals
          derivedETH
        }
        token1 {
          id
          symbol
          name
          decimals
          derivedETH
        }
        token0Price
        token1Price
        volumeUSD
        volumeToken0
        volumeToken1
        txCount
        totalValueLockedToken0
        totalValueLockedToken1
        totalValueLockedUSD
        poolDayData (orderBy: date, orderDirection:desc){
          date
          volumeUSD
          feesUSD
        }
      }
      bundles(where: {id: "1"}) {
        ethPriceUSD
      }
    }
  `;

  const json = await (await fetch(newSgMinimal, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({query}),
  })).json();

  return json;

  } catch (error) {
    console.log(error);
  }
}

// get Pool Daily Datas
export const getDailyStats = async (range, poolCA) => {
  try {
    const query = `query PoolDayDatas ($poolCA: ID!, $range: Int){
      poolDayDatas(first:$range, orderBy:date, orderDirection:desc, where:{pool: $poolCA})
      {
        date
        volumeUSD
        tvlUSD
        feesUSD
        liquidity
        high
        low
        volumeToken0
        volumeToken1
        close
        token1Price
      }
    }`

    const json = await (await fetch(newSgMinimal, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({query, variables: {poolCA, range}})
    })).json();

    return json.data.poolDayDatas; 

  } catch (error) {
  console.log(error); 
  }
};



/* ------------------------------ For Home / Generator Page ------------------------------ */

// get TVL
export const getTvl = async (positionData) => {
  try {
    let position;
    let poolIndex;
    let poolId;
    let sqrtX96;
    let investment = positionData.investment

    await uniswapPositionContract.methods.balanceOf(treasury).call()
    .then( async (res) => {

      poolIndex = +res - positionData.index;

      await uniswapPositionContract.methods.tokenOfOwnerByIndex(treasury, poolIndex).call()
      .then( async (res) => {
        poolId = res;

        await uniswapPositionContract.methods.positions(res).call().then(res => position = res);
      });
    });

    sqrtX96 =  await arb_usdc500PoolContract.methods.slot0().call();
    let tvlData = await getTokenAmounts(positionData.pool, position.liquidity, sqrtX96.sqrtPriceX96, position.tickLower, position.tickUpper, positionData.tokenPair.token0.decimal, positionData.tokenPair.token1.decimal, position.token0)

    const tvlChg = (((Number(tvlData.tvl) - investment ) / investment) * 100);

    if(tvlData){
      return {
          tvlData: tvlData,
          calTvlChg: tvlChg,
          tokenId: poolId,
          pool: arb_usdc500PoolCA,
          investment,
          token0Price: tvlData.token0Price
      };
    } 
    //   else {
    //     return {
    //       tvlData: {
    //         tvl: 0,
    //         amount0Human: 0,
    //         amount1Human: 0,
    //         amount0Percent: 0,
    //         amount1Percent: 0,
    //         token0Price: 0,
    //         investment: 0,
    //       },
    //       calTvlChg: 0,
    //       tokenId: 0,
    //       pool: 0
    //   };
    // }
  } catch (error) {console.log(error)}   
}

// get APY
export const getApy = async (poolData, t0Times, accumulatedFeesData) => {
  if(!t0Times) return;

  try {
    let currentLpToken = poolData.tokenId;
    let initInvestment = poolData.investment;
    let currentTvl = Number(poolData.tvlData.tvl);

    const today = new Date();
    // Dec 05, 2023 09:29:47 UTC
    const t0 = new Date(`${t0Times.month} ${t0Times.day}, ${t0Times.year} ${t0Times.hour}:${t0Times.minute}:${t0Times.second} UTC`)
    const timeDiff = today.getTime() - t0.getTime();
    let period = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    let periodHours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const showingPeriodData = `${period} days ${periodHours} hrs`

    // include t0 day
    if(period === 0){
      period = 1;
    }

    const encoded = {
      tokenId: currentLpToken,
      recipient: treasury,
      amount0Max: MAX,
      amount1Max: MAX
    }

    const trxCurrnet = await uniswapPositionContract.methods.collect(encoded).call();
    
    const token0Price = poolData.tvlData.token0Price;

    let amount0Human = (trxCurrnet.amount0/(10**18)).toFixed(18);
    let amount1Human = (trxCurrnet.amount1/(10**6)).toFixed(6);

    let currentFee = (Number(amount1Human) + (Number(amount0Human) * token0Price)).toFixed(0);
    if(accumulatedFeesData){
      currentFee = sum(accumulatedFeesData.totalPrice, currentFee).toFixed(0);
      amount0Human = Number(amount0Human) + Number(accumulatedFeesData.arbBalance);
      amount1Human = Number(amount1Human) + Number(accumulatedFeesData.usdcBalance);
    }

    // initInvestment = 133181;
    // currentTvl = 126243;
    // period = 40;
    // currentFee = 41401;
    
    // console.log("V0", initInvestment);
    // console.log("V1", currentTvl);
    // console.log("D", period)
    // console.log("F", currentFee);

    const periodReturn = (currentTvl - initInvestment + Number(currentFee)) / initInvestment;

    // Real APY calculation
    const tvlApy = ((1 + periodReturn) ** (365 / period) - 1) * 100;

    // ---- Calculate Fee APY ----
    //=(1+(C12/C11)*30)^12-1
    const feePeriod = 364;
    const feePeriodReturn = currentFee / initInvestment;
    // const feeApy = Math.round(((1 + (feePeriodReturn / feePeriod) * 30) ** 12 - 1) * 100);
    const feeApy = ((1 + feePeriodReturn) ** (365 / period) - 1) * 100;

    // calculate Stablecoin ratio
    // token0 = arb token1 = usdc
    const token0TvlBalance = parseFloat(poolData.tvlData.amount0Human);
    const token1TvlBalance = parseFloat(poolData.tvlData.amount1Human);
    const token0FeeBalance = amount0Human;
    const token1FeeBalance = amount1Human;

    const totalToken0Balance = token0TvlBalance + token0FeeBalance;
    const totalToken1Balance = token1TvlBalance + token1FeeBalance;

    const stablecoinRatio = (totalToken1Balance / (totalToken1Balance + totalToken0Balance)) * 100;

    return {tvlApy: tvlApy, amount0Human, amount1Human, currentFee, token0Price, period, feeApy: feeApy.toFixed(0), showingPeriodData, stablecoinRatio: stablecoinRatio.toFixed(2)};
  
  } catch (error) {console.log(error)}
}


/* ------------------------------ For Analytics Page ------------------------------ */

// Total TVL / 24hr Fees / One Token Price
export const getTodayPoolData = async (poolCA) => {
  const [t24] = getDailyUnixTimeArr();
  const block24 = await getBlock(t24);

  try {
   
    const currentData = await getPoolData(poolCA);
    const oneDayData = await getPoolData(poolCA, block24);
    
    const current = currentData.data.pools[0];
    const oneDay = oneDayData.data.pools[0];
    // const ethPriceUSD = parseFloat(currentData.data.bundles[0].ethPriceUSD);

    const feePercent = current ? parseFloat(current.feeTier) / 10000 / 100 : 0
    const tvlAdjust0 = current?.volumeToken0 ? (parseFloat(current.volumeToken0) * feePercent) / 2 : 0
    const tvlAdjust1 = current?.volumeToken1 ? (parseFloat(current.volumeToken1) * feePercent) / 2 : 0
    // const tvlToken0 = current ? parseFloat(current.totalValueLockedToken0) - tvlAdjust0 : 0
    // const tvlToken1 = current ? parseFloat(current.totalValueLockedToken1) - tvlAdjust1 : 0
    let tvlUSD = current ? parseFloat(current.totalValueLockedUSD) : 0
    
    // Part of TVL fix
    // const tvlUpdated = current
    //   ? tvlToken0 * parseFloat(current.token0.derivedETH) * ethPriceUSD +
    //   tvlToken1 * parseFloat(current.token1.derivedETH) * ethPriceUSD
    //   : undefined
    // if (tvlUpdated) {
    //   tvlUSD = tvlUpdated
    // }

    const t24VolumeUSD = current && oneDay
      ? get2DayChange(current.volumeUSD, oneDay.volumeUSD)
      : current
      ? [parseFloat(current.volumeUSD), 0]
      : [0, 0]
      
    const dailyFeeUSD = t24VolumeUSD * (current.feeTier / 1000000);

    const lastToken0Price = current.token1Price;

    let result = {
      totalValueLockedUSD: tvlUSD,
      dailyFeeUSD,
      lastToken0Price
    }

    return result;

  } catch (error) {
    console.log(error)
  }

}

// Price Volatility
export const getVolatilityData = async (range, poolCA, dailyData) => {
 
  try {

    // get Volatility
    const rangeInData = dailyData.slice(0, range);
    const closePrices = Array.from(rangeInData, d => parseFloat(d.close));
    const std = Math.sqrt(variance(closePrices, 's'));
    const mean = sumArray(closePrices) / closePrices.length;
    const volatility = (std / mean)* 100;

    return volatility;
  }
   catch (error) { console.log(error)}
}

// Active Liquidity Index / Concentrated Liquidity Index / Chart data
export const getLiquidityDatas = async (range, poolCA, dailyData) => {
  
  try {
    let result = {};

    // getNormStd
    const closePrices = Array.from(dailyData, d => parseFloat(d.close));
    const std = Math.sqrt(variance(closePrices, 's'));
    const mean = sumArray(closePrices) / closePrices.length;
    const volatility = (std / mean)* 100;

    // get ALI
    const targetPool = await getPoolData(poolCA);
    const target24hVolumeUSD = targetPool.data.pools[0].poolDayData[1].volumeUSD;
    const target24HFeesUSD = targetPool.data.pools[0].poolDayData[1].feesUSD;
    let ali = calc24HrFee(dailyData[1], targetPool.data.pools[0], target24hVolumeUSD, target24HFeesUSD);

    // get CLI
    let basePrice = targetPool.data.pools[0].token0Price;
    const ticks = await liquidityByPoolId(poolCA);
    const liquidityData = await genLiquidityData(ticks, +targetPool.data.pools[0].feeTier);
    const cli = calcCLI(liquidityData, volatility, targetPool, basePrice, 1);

    // get Chart data - Volume / Fees
    let volumeArr = [];
    let feesArr = [];

    dailyData.forEach(data => {
      volumeArr.push({
        time: unixToDate(data.date),
        value: +data.volumeUSD === 0 ? 0 : +(+data.volumeUSD).toFixed(10)
      })

      feesArr.push({
        time: unixToDate(data.date),
        value: +data.feesUSD === 0 ? 0 : +(+data.feesUSD).toFixed(10)
      })
    });

    result = {
      ali,
      cli: cli.cli,
      chartData:{
        volume: volumeArr.reverse(),
        fees: feesArr.reverse()
      },
      targetPool
    }

    return result;

  } catch (error) { console.log(error)}
}

// Liquidity Density Chart data
export const fetchTicksSurroundingPrice = async (targetPool, numSurroundingTicks = 300) => {
  const PRICE_FIXED_DIGITS = 4
  
  // poolData
  const poolData = targetPool.data.pools[0];
  const formattedAddress0 = poolData.token0.id;
  const formattedAddress1 = poolData.token1.id;
  const feeTier = poolData.feeTier;
  // parsed tokens
  const token0 = genToken(ARBITRUM_CHAINID, formattedAddress0, parseInt(poolData.token0.decimals));
  const token1 = genToken(ARBITRUM_CHAINID, formattedAddress1, parseInt(poolData.token1.decimals));

   // tick data tracking
  const poolCurrentTickIdx = parseInt(poolData.tick);
  const tickSpacing = FEE_TIER_TO_TICK_SPACING(feeTier);

  const activeTickIdx = Math.floor(poolCurrentTickIdx / tickSpacing) * tickSpacing;

  const tickIdxLowerBound = activeTickIdx - numSurroundingTicks * tickSpacing
  const tickIdxUpperBound = activeTickIdx + numSurroundingTicks * tickSpacing

  const initializedTicksResult = await fetchInitializedTicks(poolData.id, tickIdxLowerBound, tickIdxUpperBound);

  const { ticks: initializedTicks } = initializedTicksResult

  const tickIdxToInitializedTick = keyBy(initializedTicks, 'tickIdx')

  let activeTickIdxForPrice = activeTickIdx
  if (activeTickIdxForPrice < TickMath.MIN_TICK) {
    activeTickIdxForPrice = TickMath.MIN_TICK
  }
  if (activeTickIdxForPrice > TickMath.MAX_TICK) {
    activeTickIdxForPrice = TickMath.MAX_TICK
  }

  const activeTickProcessed = {
    liquidityActive: JSBI.BigInt(poolData.liquidity),
    tickIdx: activeTickIdx,
    liquidityNet: JSBI.BigInt(0),
    price0: tickToPrice(token0, token1, activeTickIdxForPrice).toFixed(PRICE_FIXED_DIGITS),
    price1: tickToPrice(token1, token0, activeTickIdxForPrice).toFixed(PRICE_FIXED_DIGITS),
    liquidityGross: JSBI.BigInt(0),
  }

  const activeTick = tickIdxToInitializedTick[activeTickIdx]
  if (activeTick) {
    activeTickProcessed.liquidityGross = JSBI.BigInt(activeTick.liquidityGross)
    activeTickProcessed.liquidityNet = JSBI.BigInt(activeTick.liquidityNet)
  }

  const computeSurroundingTicks = (activeTickProcessed, tickSpacing, numSurroundingTicks, direction) => {
    let previousTickProcessed = {
      ...activeTickProcessed,
    }

    // Iterate outwards (either up or down depending on 'Direction') from the active tick,
    // building active liquidity for every tick.
    let processedTicks = []
    for (let i = 0; i < numSurroundingTicks; i++) {
      const currentTickIdx =
        direction === "ASC"
          ? previousTickProcessed.tickIdx + tickSpacing
          : previousTickProcessed.tickIdx - tickSpacing

      if (currentTickIdx < TickMath.MIN_TICK || currentTickIdx > TickMath.MAX_TICK) {
        break
      }

      const currentTickProcessed = {
        liquidityActive: previousTickProcessed.liquidityActive,
        tickIdx: currentTickIdx,
        liquidityNet: JSBI.BigInt(0),
        price0: tickToPrice(token0, token1, currentTickIdx).toFixed(PRICE_FIXED_DIGITS),
        price1: tickToPrice(token1, token0, currentTickIdx).toFixed(PRICE_FIXED_DIGITS),
        liquidityGross: JSBI.BigInt(0),
      }

      // Check if there is an initialized tick at our current tick.
      // If so copy the gross and net liquidity from the initialized tick.
      const currentInitializedTick = tickIdxToInitializedTick[currentTickIdx.toString()]
      if (currentInitializedTick) {
        currentTickProcessed.liquidityGross = JSBI.BigInt(currentInitializedTick.liquidityGross)
        currentTickProcessed.liquidityNet = JSBI.BigInt(currentInitializedTick.liquidityNet)
      }

      // Update the active liquidity.
      // If we are iterating ascending and we found an initialized tick we immediately apply
      // it to the current processed tick we are building.
      // If we are iterating descending, we don't want to apply the net liquidity until the following tick.
      if (direction === "ASC" && currentInitializedTick) {
        currentTickProcessed.liquidityActive = JSBI.add(
          previousTickProcessed.liquidityActive,
          JSBI.BigInt(currentInitializedTick.liquidityNet)
        )
      } else if (direction === "DESC" && JSBI.notEqual(previousTickProcessed.liquidityNet, JSBI.BigInt(0))) {
        // We are iterating descending, so look at the previous tick and apply any net liquidity.
        currentTickProcessed.liquidityActive = JSBI.subtract(
          previousTickProcessed.liquidityActive,
          previousTickProcessed.liquidityNet
        )
      }

      processedTicks.push(currentTickProcessed)
      previousTickProcessed = currentTickProcessed
    }

    if (direction === "DESC") {
      processedTicks = processedTicks.reverse()
    }

    return processedTicks
  }

  const subsequentTicks = computeSurroundingTicks(
    activeTickProcessed,
    tickSpacing,
    numSurroundingTicks,
    "ASC"
  )

  const previousTicks = computeSurroundingTicks(
    activeTickProcessed,
    tickSpacing,
    numSurroundingTicks,
    "DESC"
  )

  const ticksProcessed = previousTicks.concat(activeTickProcessed).concat(subsequentTicks)
  
  return {
    data: {
      ticksProcessed,
      feeTier,
      tickSpacing,
      activeTickIdx,
    }
  }
}

// DefiLlama Yields Pool data
export const getAllDefiLlamaPoolsData = async () => {
  const result = await customAxios.get("/pools");
  return result;
};

export const getEthApy = async () => {
  try {
    const result = await axios.get("https://beaconcha.in/api/v1/ethstore/latest", {
      headers: {
        apikey: process.env.REACT_APP_BEACONCHA_API_KEY
      }
    });
  
    return(result.data.data.apr);
 
  } catch (error) {
    return(0);    
  }
}
  
// get Dai Savings Rate
export const getDaiSavingsRate = async () => {
  const dsr = await makerMCDPotContract.methods.dsr().call();

  const result = calcFee(dsr) * 100;

  return result;
}

/* ------------------------------ For Strategies Page ------------------------------ */
// get slot0 data at pool
export const getSlot0AtPool = async () => {
  const {sqrtPriceX96, tick} = await arb_usdc500PoolContract.methods.slot0().call();
  
  return {sqrtPriceX96, currentTick: tick};
}

// get balance of Target pool's
export const getBalanceOfTargetPool = async (poolCA, token1Decimal) => {
  const totalToken0InPool = ethers.utils.formatEther(await arbTokenContract.methods.balanceOf(poolCA).call());
  const totalToken1InPool = ethers.utils.formatUnits(await usdcTokenContract.methods.balanceOf(poolCA).call(), token1Decimal);

  return {totalToken0InPool, totalToken1InPool}
}

// get arb, usdc balance of Target Wallet
export const getBalanceOfTargetWallet = async (account, token0Price) => {
  const arbBalance = ethers.utils.formatEther(await arbTokenContract.methods.balanceOf(account).call());
  const usdcBalance = ethers.utils.formatUnits(await usdcTokenContract.methods.balanceOf(account).call(), 6);

  // const oneArbPrice = await getOneTokenPrice(null, pool);
  const totalArbPrice = multiply(arbBalance, token0Price);

  const totalPrice =  sum(usdcBalance, totalArbPrice);

  return {totalPrice, arbBalance, usdcBalance};
}