import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Bar, BarChart, ResponsiveContainer, XAxis, Tooltip, LabelList } from "recharts";
import { ARBITRUM_CHAINID } from "../../common/web3/address";
import { fetchTicksSurroundingPrice } from "../../common/web3/api";
import { formatAmount, genToken, MAX, truncateToDecimals } from "../../common/web3/utils";
import { Pool, TickMath, TICK_SPACINGS } from '@uniswap/v3-sdk'
import { CurrencyAmount } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import CircularProgress from '@mui/material/CircularProgress';

const INITIAL_TICKS_TO_FETCH = 200
const ZOOM_INTERVAL = 20

const initialState = {
  left: 0,
  right: INITIAL_TICKS_TO_FETCH * 2 + 1,
  refAreaLeft: '',
  refAreaRight: '',
}

function LiquidityChart ({ targetPool }) {
  const poolData = targetPool?.data.pools[0];
  const formattedAddress0 = poolData.token0.id;
  const formattedAddress1 = poolData.token1.id;
  const feeTier = poolData.feeTier;

  const token0 = genToken(ARBITRUM_CHAINID, formattedAddress0, parseInt(poolData.token0.decimals));
  const token1 = genToken(ARBITRUM_CHAINID, formattedAddress1, parseInt(poolData.token1.decimals));

  const [poolTickData, updatePoolTickData] = useState();
  const [ticksToFetch, setTicksToFetch] = useState(INITIAL_TICKS_TO_FETCH);
  const amountTicks = ticksToFetch * 2 + 1;

  const [loading, setLoading] = useState(true);
  const [zoomState, setZoomState] = useState(initialState);

  const [formattedData, setFormattedData] = useState();
  const [currentPrice, setCurrentPrice] = useState();

  useEffect(() => {
    async function fetch() {
      const { data } = await fetchTicksSurroundingPrice(targetPool, ticksToFetch)
      if (data) {
        updatePoolTickData(data);
      }
    }
    if (!poolTickData || (poolTickData && poolTickData.ticksProcessed.length < amountTicks)) {
      fetch();
    }

  }, [poolTickData, updatePoolTickData, ticksToFetch, amountTicks, targetPool])

  useEffect(() => {
    async function formatData() {
      if (poolTickData) {
        const newData = await Promise.all(
          poolTickData.ticksProcessed.map(async (t, i) => {
            const active = t.tickIdx === poolTickData.activeTickIdx
            const sqrtPriceX96 = TickMath.getSqrtRatioAtTick(t.tickIdx)
            const feeAmount = poolData.feeTier
            const mockTicks = [
              {
                index: t.tickIdx - TICK_SPACINGS[feeAmount],
                liquidityGross: t.liquidityGross,
                liquidityNet: JSBI.multiply(t.liquidityNet, JSBI.BigInt('-1')),
              },
              {
                index: t.tickIdx,
                liquidityGross: t.liquidityGross,
                liquidityNet: t.liquidityNet,
              },
            ]
            const pool =
              token0 && token1 && feeTier
                ? new Pool(token0, token1, +feeTier, sqrtPriceX96, t.liquidityActive, t.tickIdx, mockTicks)
                : undefined
            const nextSqrtX96 = poolTickData.ticksProcessed[i - 1]
              ? TickMath.getSqrtRatioAtTick(poolTickData.ticksProcessed[i - 1].tickIdx)
              : undefined
            const maxAmountToken0 = token0 ? CurrencyAmount.fromRawAmount(token0, MAX.toString()) : undefined
            const outputRes0 =
              pool && maxAmountToken0 ? await pool.getOutputAmount(maxAmountToken0, nextSqrtX96) : undefined

            const token1Amount = outputRes0?.[0]

            const amount0 = token1Amount ? parseFloat(token1Amount.toExact()) * parseFloat(t.price1) : 0
            const amount1 = token1Amount ? parseFloat(token1Amount.toExact()) : 0

            return {
              index: i,
              isCurrent: active,
              activeLiquidity: parseFloat(t.liquidityActive.toString()),
              price0: parseFloat(t.price0),
              price1: parseFloat(t.price1),
              tvlToken0: amount0,
              tvlToken1: amount1,
            }
          })
        )

        if (newData) {
          // 24.03.08 데이터 오류로 발생된 음수값 임시 제거
          const filteredNegativeValue = newData.filter((data) => data.activeLiquidity > 0);
          setFormattedData(filteredNegativeValue)
        }
      } else {
        return []
      }
    }
    if (!formattedData) {
      formatData();
    }
  }, [feeTier, formattedData, loading, poolData.feeTier, poolTickData, token0, token1])

  const atZoomMax = zoomState.left + ZOOM_INTERVAL >= zoomState.right - ZOOM_INTERVAL - 1
  const atZoomMin = zoomState.left - ZOOM_INTERVAL < 0

  const handleZoomIn = useCallback(() => {
    !atZoomMax &&
      setZoomState({
        ...zoomState,
        left: zoomState.left + ZOOM_INTERVAL,
        right: zoomState.right - ZOOM_INTERVAL,
      })
  }, [zoomState, atZoomMax])

  const handleZoomOut = useCallback(() => {
    if (atZoomMin) {
      setLoading(true)
      setTicksToFetch(ticksToFetch + ZOOM_INTERVAL)
      setFormattedData(undefined)
      setZoomState({
        ...zoomState,
        left: 0,
        right: amountTicks,
      })
    } else {
      setZoomState({
        ...zoomState,
        left: zoomState.left - ZOOM_INTERVAL,
        right: zoomState.right + ZOOM_INTERVAL,
      })
    }
  }, [amountTicks, atZoomMin, ticksToFetch, zoomState])

  const zoomedData = useMemo(() => {
    if (formattedData) {
      const findCurrentPrice = formattedData.find(data => data.isCurrent === true );
      setCurrentPrice(findCurrentPrice)

      return formattedData.slice(zoomState.left, zoomState.right)
    }
    return undefined
  }, [formattedData, setCurrentPrice, zoomState.left, zoomState.right])

  const CustomToolTip = ({ chartProps, poolData, currentPrice}) => {
    const price0 = chartProps?.payload?.[0]?.payload.price0
    const price1 = chartProps?.payload?.[0]?.payload.price1
    const tvlToken0 = chartProps?.payload?.[0]?.payload.tvlToken0
    const tvlToken1 = chartProps?.payload?.[0]?.payload.tvlToken1
    let token0symbol = poolData?.token0?.symbol;
    let token1symbol = poolData?.token1?.symbol;

    if(token0symbol === "WETH") {
      token0symbol = "ETH";
    }
    return (
      <div className='pooldeviation-section__contents--chartWrap--tooltipWrapper'>
        <h1>Tick stats</h1>
        <div className='pooldeviation-section__contents--chartWrap--tooltipWrapper__dataLine'>
          <label>{token0symbol} Price: </label>
          <label>
            {price0 ? Number(price0).toLocaleString(undefined, { minimumSignificantDigits: 1 }) :  ''}
            {' '}
            {token1symbol}
          </label>
        </div>
        <div className='pooldeviation-section__contents--chartWrap--tooltipWrapper__dataLine'>
          <label>{token1symbol} Price: </label>
          <label>
            {price1 ? Number(price1).toLocaleString(undefined, { minimumSignificantDigits: 1 }) :  ''}
            {' '}
            {token0symbol}
          </label>
        </div>
        {currentPrice && price0 && currentPrice > price1 ? (
          <div className='pooldeviation-section__contents--chartWrap--tooltipWrapper__dataLine'>
            <label>{token0symbol} Locked: </label>
            <label>{tvlToken0 ? formatAmount(tvlToken0) : ''} {token0symbol}</label>
          </div>
        ) : (
          <div className='pooldeviation-section__contents--chartWrap--tooltipWrapper__dataLine'>
            <label>{token1symbol} Locked: </label>
            <label>{tvlToken1 ? formatAmount(tvlToken1) : ''} {token1symbol}</label>
          </div>
        )}
      </div>
    );
  }

  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
  
    return (
      <>
        {value &&
          <g>
            <text x={x + width / 2} y={y - 10} fill="#FF00FF" textAnchor="middle" dominantBaseline="middle">
              ${truncateToDecimals(currentPrice.price0, 2)}
            </text>
          </g>
        }
      </>
    );
  };

  useEffect(() => {
    if(zoomedData){
      setLoading(false);
    }
  }, [zoomedData])


  return (
    <>
      {!loading ? (
        <ResponsiveContainer width="99%" height="99%">
          <BarChart
            data={zoomedData}
            margin={{
              top: 0,
              right: 0,
              bottom: -30,
              left: 0
            }}
          >
            <XAxis
              reversed={false} 
              tick={false}
              />
            <Tooltip
              cursor={{ fill: "#666" }}
              content={(props) => (
                <CustomToolTip chartProps={props} poolData={poolData} currentPrice={poolData.token0Price} />
              )}
            />
            <Bar
              dataKey="activeLiquidity"
              fill={'#000'}
              isAnimationActive={false}
              shape={(props) => {
                return (
                  <g>
                    <rect x={props.x} y={props.y} fill={props.payload.isCurrent ? "#FF007A" : "#56B2A4"} width={props.width} height={props.height} rx="2" />
                  </g>
                )
              }}
            >
              <LabelList dataKey="isCurrent" content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div className='chart-loading-wrap'>
          <CircularProgress size={30} />
        </div>
      )}
      <div className="pooldeviation-section__contents--chartWrap--controlsWrapper">
          <div className="zoomBtn" disabled={false} onClick={handleZoomOut}>
            -
          </div>
          <div className="zoomBtn" disabled={atZoomMax} onClick={handleZoomIn}>
            +
          </div>
      </div>
    </>
  )
}

export default LiquidityChart;
