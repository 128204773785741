import React from 'react'
import styled from "styled-components";

const Section = styled.section`
    position: relative;
    height: 100vh;
`

const Div = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: auto;
`

const ErrorTit=styled.h2`
    font-family: var(--font2);
    font-size: 12em;
    color: #fff;
    line-height: 64px;
    text-align: center;
    letter-spacing: 0.37px;
    @media screen and (max-width: 1000px) {
        font-size: 6em;
    }
`

const Txt=styled.p`
    padding-top: 70px;
    font-family: 'WorkSansR';
    font-size: 30px;
    color: rgba(250,250,250,0.5);
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.24px;
    @media screen and (max-width: 1000px) {
        padding-top: 25px;
        font-size: 20px;
    }
`

function Error(){
    return<>
        <Section className="section__area">
            <Div className="container page__container">
                <ErrorTit>404</ErrorTit>
                <Txt>Page Not Found</Txt>
            </Div>
        </Section>
    </>
}

export default Error