import "./popupBtn.scss";
import {Dialog, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";

function PopupBtn ({title, contents}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="popup-main" onClick={() => setOpen(true)}>?</div>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle className="dialog-title-wrap">
          <h2>{title}</h2>
          <CloseIcon className="close-Ico" onClick={() => setOpen(false)}/>
        </DialogTitle>
        <div class="dialog-contents-wrap">
          {contents}
        </div>
      </Dialog >
    </>
  )
}

export default PopupBtn;